import * as React from "react";
import Index from "../../../container/Index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Index.Box className="faq-wrape">
        <Index.Box className="px-30">
          <Index.Grid container spacing={3}>
            <Index.Grid item lg={4} md={6} sm={12} xs={12}>
              <Index.Box className="head-section">
                <Index.Box className="title-wrape">
                  <Index.Typography component="p" className="head-label">
                    FAQ
                  </Index.Typography>
                  <Index.Typography component="h4">
                    Common Queries!
                  </Index.Typography>
                  <Index.Typography component="p">
                    Everything you need to know about the Plutope.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item lg={8} md={6} sm={12} xs={12}>
              <Index.Box className="faq-section">
                <Index.Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  className="accr-pad"
                >
                  <Index.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accr-summary"
                  >
                    <Index.Typography className="number">1.</Index.Typography>
                    <Index.Typography className="head-label">
                      What is PLT?
                    </Index.Typography>
                  </Index.AccordionSummary>
                  <Index.AccordionDetails>
                    <Index.Typography>
                      PLT is the official <b>token</b> of the PlutoPe.
                    </Index.Typography>
                  </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  className="accr-pad"
                >
                  <Index.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accr-summary"
                  >
                    <Index.Typography className="number">2.</Index.Typography>
                    <Index.Typography className="head-label">
                      Will I get my PLT token immediately when I buy it?
                    </Index.Typography>
                  </Index.AccordionSummary>
                  <Index.AccordionDetails>
                    <Index.Typography>
                      No, Your PLT will be staked for 24 months. However, you
                      can withdraw up to 4.166% of your PLT tokens every month
                      after initial lockup period of 3 months.
                    </Index.Typography>
                  </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                  className="accr-pad"
                >
                  <Index.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accr-summary"
                  >
                    <Index.Typography className="number">3.</Index.Typography>
                    <Index.Typography className="head-label">
                      Why should I buy PLT tokens?
                    </Index.Typography>
                  </Index.AccordionSummary>
                  <Index.AccordionDetails>
                    <Index.Typography>
                      You should buy PLT tokens during pre-sale to get PLT
                      tokens at a discounted rate also holding PLT tokens will
                      provide you priority access to our new offers and
                      features.
                    </Index.Typography>
                  </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                  className="accr-pad"
                >
                  <Index.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accr-summary"
                  >
                    <Index.Typography className="number">4.</Index.Typography>
                    <Index.Typography className="head-label">
                      When will PLT get listed on an exchange?
                    </Index.Typography>
                  </Index.AccordionSummary>
                  <Index.AccordionDetails>
                    <Index.Typography>Coming soon</Index.Typography>
                  </Index.AccordionDetails>
                </Index.Accordion>
                <Index.Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                  className="accr-pad"
                >
                  <Index.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="accr-summary"
                  >
                    <Index.Typography className="number">5.</Index.Typography>
                    <Index.Typography className="head-label">
                      What is Presale?
                    </Index.Typography>
                  </Index.AccordionSummary>
                  <Index.AccordionDetails>
                    <Index.Typography>
                      A token presale is a fundraising method commonly used in
                      the cryptocurrency and blockchain space. It is a
                      pre-initial coin offering <b>(ICO)</b> or pre-token sale
                      where a project sells a certain amount of its
                      cryptocurrency tokens to a select group of investors
                      before the tokens are made available to the general public
                      during the official token sale.
                    </Index.Typography>
                  </Index.AccordionDetails>
                </Index.Accordion>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
}
