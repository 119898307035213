import React, {useState, useEffect} from "react";
import Index from "../../Index";
import PageIndex from "../../PagesIndex";



export default function HeroTwo()  {
    /* dd start */
    const [crypto, setCrypto] = React.useState('');  
    const handleChange = (event) => {
      setCrypto(event.target.value);
    };
    /* dd end */

    /* countdown start */
    const [countdownDate, setCountdownDate] = useState(new Date('12/25/2024').getTime());
    const [state, setState] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    useEffect(() => {
      setInterval(() => setNewTime(), 1000);
    }, []);
  
    const setNewTime = () => {
      if (countdownDate) {
        const currentTime = new Date().getTime();
  
        const distanceToDate = countdownDate - currentTime;
  
        let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        let minutes = Math.floor(
          (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
        );
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
  
        const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  
        days = `${days}`;
        if (numbersToAddZeroTo.includes(hours)) {
          hours = `0${hours}`;
        } else if (numbersToAddZeroTo.includes(minutes)) {
          minutes = `0${minutes}`;
        } else if (numbersToAddZeroTo.includes(seconds)) {
          seconds = `0${seconds}`;
        }
  
        setState({ days: days, hours: hours, minutes, seconds });
      }
    };
  
    /* countdown end */

//     const [isShowFirstSection, setIsShowFirstSection] = React.useState(false);
//   const scrolled50Ref = React.useRef();
//     useEffect(() => {
//       const scrollFun = () => {
//         console.log("window.scrollY", window.scrollY);
//         if (window.scrollY <= 80) {
//           setIsShowFirstSection(false);
//         } else {
//           setIsShowFirstSection(true);
//         }
//       };
  
//       window.addEventListener("scroll", scrollFun);
  
//       return () => {
//         window.removeEventListener("scroll", scrollFun);
//       };
//     }, []);
  return (
    <>
        <Index.Box className="home-hero-main one" >
          <Index.Box className="hero-text-area">
            <Index.Typography component="h1" className="text">
              Lorem ipsum <Index.Box component="span" className="sub"> dolor</Index.Box>
            </Index.Typography>
            <Index.Typography component="p">
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.
            </Index.Typography>
            <Index.Box className="btn-wrape">
              <PageIndex.Button primary>litepaper</PageIndex.Button>
              <PageIndex.Button secondary>Watch Video</PageIndex.Button>
            </Index.Box>
          </Index.Box>
  
          <Index.Box className="presale-wrape">
            <Index.Typography component="h4">
              Strategic  
              <Index.Box className="label-wrape">
                <PageIndex.OpenLabel />
              </Index.Box>
            </Index.Typography>
      
            <Index.Box className="transfer-wrape"> 
              <Index.Box className="text-wrape">
                <Index.Typography component="h6">
                  <Index.Box className="icon">
                    <PageIndex.Svg.BNB />
                  </Index.Box> Buy with BSC
                </Index.Typography>
  
                <Index.Typography component="h6">
                  1 PLT = $0.033
                </Index.Typography>
              </Index.Box>
              <Index.Box className="gray-pad">
                <Index.Typography component="p">You Pay</Index.Typography>
                <Index.Box className="inner-pad">
                  <Index.Box className="input-wrape">
                    <Index.Box className="input-pad">
                      <Index.TextField placeholder="Enter amount" type="number"></Index.TextField>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-wrape">
                    <Index.Box className="input-pad">
                      <Index.FormControl fullWidth>
                        <Index.Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={crypto}
                          label="crypto"
                          onChange={handleChange}
                        >
                          <Index.MenuItem value={10}>
                            <Index.Box className="dd-icon">
                              <PageIndex.Svg.TUSDT />
                            </Index.Box>
                            USDT
                          </Index.MenuItem>
                          <Index.MenuItem value={20}>
                            <Index.Box className="dd-icon">
                              <PageIndex.Svg.BNB />
                            </Index.Box>
                            BNB
                          </Index.MenuItem> 
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Typography component="p" className="base-price">
                  Strategic Price <Index.Typography component="b">0.033</Index.Typography>
                </Index.Typography>
              </Index.Box>
  
              <Index.Box className="gray-pad">
                <Index.Typography component="p">You Receive</Index.Typography>
                <Index.Typography component="h1">
                  <Index.Box className="p-currency">
                    <PageIndex.Svg.logoSymbol />
                  </Index.Box>
                  2130
                </Index.Typography>
              </Index.Box>
                          
              <Index.Box className="progress-wrape">
                <Index.Typography component="p">Until next price <Index.Typography component="b">$0.067</Index.Typography>
                </Index.Typography>
                <Index.Box className="progress-pad">
                  <Index.LinearProgress  variant="determinate" value={50} />
                </Index.Box>
                <Index.Typography component="p">0 PLT /54750000 PLT</Index.Typography>
              </Index.Box>
  
              <Index.Box className="footer-action">
                <Index.Button type="submit" className="submit-btn wallet-connetct">
                  <Index.Box component="span" className="icon">
                    <PageIndex.Svg.ConnectWallet />
                  </Index.Box> Connect Wallet 
                </Index.Button>
              </Index.Box>
            </Index.Box>
  
            {/* <Index.Box className="process-wrape">
              <Index.Typography component="p">
                Until next price$0.067
              </Index.Typography>
              <Index.Typography component="p">
                0 PLT /54750000 PLT
              </Index.Typography>
            </Index.Box>
            <Index.Box className="start-price-wrape">
              <Index.Typography component="p">
                Strategic Price
              </Index.Typography>
              <Index.Typography component="h4">
                0.033
              </Index.Typography>
            </Index.Box> */}
  
          </Index.Box>
  
          <div className='countdown-wrapper'>
            <Index.Typography component="h5" className="box-head-text">End Soon</Index.Typography>
            <Index.Typography component="p">
              Lorem ipsum dolor sit amet, co tur adipiscing elit
            </Index.Typography>
            <Index.Box className="countdown-pad">
              <Index.Box className='time-section'>
                <Index.Box className='time'>{state.days || '0'}</Index.Box>
                <Index.Box component="small" className="time-text">Days</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='dots'>:</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='time'>{state.hours || '00'}</Index.Box>
                <Index.Box component="small" className="time-text">Hours</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='dots'>:</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='time'>{state.minutes || '00'}</Index.Box>
                <Index.Box component="small" className="time-text">Minutes</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='dots'>:</Index.Box>
              </Index.Box>
              <Index.Box className='time-section'>
                <Index.Box className='time'>{state.seconds || '00'}</Index.Box>
                <Index.Box component="small" className="time-text">Seconds</Index.Box>
              </Index.Box>
            </Index.Box>
          </div>

          <Index.Box className="glass-bg">
            <img src={PageIndex.Png.glass} alt="" />
          </Index.Box>
        </Index.Box>
    </>
  )
}