import React from 'react'

export default function ModalCloseIcon() {
  return (
    <>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.5645 24.3485L12.1732 14.9572L2.78191 24.3485L0 21.5645L9.3913 12.1732L0 2.78191L2.78191 0L12.1732 9.3913L21.5645 0L24.3485 2.78191L14.9572 12.1732L24.3485 21.5645L21.5645 24.3485Z" fill="black"/>
</svg>

    </>
  )
}
