import React, { useEffect, useState, useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import Index from '../../Index';
import PagesIndex from '../../PagesIndex';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReadMoreReadLessBox from './ReadMoreReadLessBox';


export default function RoadMap(props) {
    const [isActive, setActive] = useState([false, false, false, false, false, false]);
    // Q1 - (2024)
    const [isShowQuaterOne2024, setIsShowQuaterOne2024] = useState(false);
    const [isShowQuaterOne2022, setIsShowQuaterOne2022] = useState(false);

    const toggleClass = (index) => {
        setActive((data) =>
            [...data.slice(0, index),
            !data[index],
            ...data.slice(index + 1)]
        );
    };

    const [expanded, setExpanded] = React.useState('panel1');


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const roadMapRef = useRef(null);
    const [showMore, setShowMore] = useState(false);
    const scrollToElementTop = () => {
        setShowMore(!showMore);
        if (roadMapRef.current) {
            roadMapRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
        }

    };

    // when the show more Quater one 2024 ( Q1 - (2022)) then used...
    const handleQuaterOne2022Show = React.useCallback(() => {
        if (!isShowQuaterOne2022) {
            setIsShowQuaterOne2022(true)
        } else {
            setIsShowQuaterOne2022(false)
        }
    }, [isShowQuaterOne2024, setIsShowQuaterOne2024])

    // when the show more Quater one 2024 ( Q1 - (2024)) then used...
    const handleQuaterOne2024Show = React.useCallback(() => {
        if (!isShowQuaterOne2024) {
            setIsShowQuaterOne2024(true)
        } else {
            setIsShowQuaterOne2024(false)
        }
    }, [isShowQuaterOne2024, setIsShowQuaterOne2024])

    return (
        <>
            <Index.Box className="roadmap-wrape" >
                <Index.Box className="">
                    <Index.Box className="head-section">
                        <Index.Box className="title-wrape">
                            <Index.Typography component="p">
                                RoadMap
                            </Index.Typography>
                            <Index.Typography component="h4">
                                Our Journey
                            </Index.Typography>
                        </Index.Box>
                        {/* <Index.Box className="controls">
                            <Index.Box className="swiper-button-prev">
                                <PagesIndex.Svg.PrevArrow />
                            </Index.Box>
                            <Index.Box className="swiper-button-next">
                                <PagesIndex.Svg.NextArrow />
                            </Index.Box>
                        </Index.Box> */}
                    </Index.Box>
                </Index.Box>

                <Index.Box className="swipper-main-wrape">
                    <Index.Box className="swipper-main-wrape">
                        <Swiper
                             autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                              }}
                            slidesPerView={4}
                            spaceBetween={30}
                            grabCursor={true}
                            breakpoints={{
                                280: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1280: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            pagination={{
                                type: 'progressbar',
                                el: '.swiper-custom-pagination',
                            }}
                            modules={[Pagination, Navigation, Autoplay]}
                            className="mySwiper"
                            // navigation={{
                            //     nextEl: ".swiper-button-next",
                            //     prevEl: ".swiper-button-prev",
                            // }}
                            navigation={true}
                        >
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q1 - (2022)
                                    </Index.Typography>

                                    {/* <Index.Box className={`roadmap-content-block ${ isActive[0]  ? 'active': null}`} >
                            <Index.Box className="roadmap-bullet-block" style={{ transform: isActive[0]  ? "translate(0px, -54%)" : "translate(0px, 0%)" }}></Index.Box> */}

                                    <Index.Box className="roadmap-content-block" >
                                        <Index.Box className="roadmap-bullet-block" >
                                        <ReadMoreReadLessBox items={[
                                                " PlutoPe non-custodial wallet conceptions",
                                                "Deep research and analysis.",
                                                "Identifying user needs.",
                                                "Incorporation of PlutoPe.",
                                            ]}
                                                bulletIcon={<PagesIndex.Svg.GreenBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    PlutoPe non-custodial wallet conceptions
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Deep research and analysis.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Identifying user needs.
                                                </Index.Box>
                                            </Index.Box>
                                           
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.GreenBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            Incorporation of PlutoPe.
                                                        </Index.Box>
                                                    </Index.Box> */}
                                        </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Typography className='read-more-text' onClick={handleQuaterOne2022Show}>
                                        {isShowQuaterOne2022 ? 'Read Less11' : 'Read More11'}
                                    </Index.Typography> */}
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q2 - (2022)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Onboarding development and product team
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Developing Plutope's architecture
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q3 - (2022)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Product development begins for iOS and Android.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Hiring for in-house marketing team and CMO.
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q4 - (2022)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Wallet deployment on Binance Smart Chain complete.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    EVM chain integration begins.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.GreenBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Alpha testing for wallet APK begins within the team.
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q1 - (2023)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <ReadMoreReadLessBox items={[
                                                "Marketing campaigns in different regions such as APAC and MENA.",
                                                "Partnership with off/on ramp providers.",
                                                "Integration and testing for off/on ramp begin.",
                                                "Contract signing with MasterCard card provider.",
                                                "Tokenomics for reward tokens begins.",
                                            ]}
                                                bulletIcon={<PagesIndex.Svg.BlueBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Marketing campaigns in different regions such as APAC and MENA.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Partnership with off/on ramp providers.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Integration and testing for off/on ramp begin.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Contract signing with MasterCard card provider.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet" ref={roadMapRef} >
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text" >
                                                    Tokenomics for reward tokens begins.
                                                </Index.Box>
                                            </Index.Box> */}
                                        </Index.Box>

                                    </Index.Box>
                                    {/* <Index.Box className="read-more-text" onClick={scrollToElementTop}>Read More</Index.Box> */}
                                    {/* <Index.Typography className='read-bullet-text'
                        onClick={()=>{toggleClass(1)}}
                        >
                            { isActive[1]  ? 'Read Less': 'Read More'}
                        </Index.Typography> */}
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q2 - (2023)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <ReadMoreReadLessBox items={[
                                                "Plutope app beta testing begins on ETH, Polygon, Bsc",
                                                "KYB for Crypto Debit Card approval with partner.",
                                                "Partnership with audit firms.",
                                                "Tokenomics is finalised and the token contract begins",
                                            ]}
                                                bulletIcon={<PagesIndex.Svg.BlueBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Plutope app beta testing begins on ETH, Polygon, Bsc
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    KYB for Crypto Debit Card approval with partner.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Partnership with audit firms.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text" ref={roadMapRef}>
                                                    Tokenomics is finalised and the token contract begins
                                                </Index.Box>
                                            </Index.Box> */}
                                        </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Typography className='read-more-text' onClick={scrollToElementTop}>
                                        {showMore ? 'Read Less' : 'Read More'}
                                    </Index.Typography> */}
                                    {/* <Index.Typography className='read-bullet-text'
                                        onClick={() => { toggleClass(2); scrollToElementTop(); }} >
                                        {isActive[2] ? 'Read Less' : 'Read More'}
                                    </Index.Typography> */}
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q3 - (2023)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Beta Testing (Bug Bounty) of the App with Crypto Community & developers
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    App launch on Play Store (Testing) & Apple Store (Test Flight)
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q4 - (2023)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <ReadMoreReadLessBox items={[
                                                "Integration of Bitcoin chain",
                                                "Launch new website",
                                                "Launch of plutope academy",
                                                "Beta testing complete",
                                                "Implementation of new backend",
                                                "Introduce Qr code to request amount in FIAT",
                                            ]}
                                                bulletIcon={<PagesIndex.Svg.BlueBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Integration of Bitcoin chain
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Launch new website
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Launch of plutope academy
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Beta testing complete
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Implementation of new backend
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlueBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Introduce Qr code to request amount in FIAT
                                                </Index.Box>
                                            </Index.Box> */}
                                        </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Typography className='read-bullet-text' 
                        onClick={()=>{toggleClass(3)}} >
                            { isActive[3]  ? 'Read Less': 'Read More'}
                        </Index.Typography> */}
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q1 - (2024)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <ReadMoreReadLessBox items={[
                                                "PlutoPe App launch on Google Play Store",
                                                "Plutope App Launch on Apple Store",
                                                "New Swap Provider: ChangeNow",
                                                "New On/Off Ramp: Unlimit Crypto",
                                                "500 Downloads",
                                                "Multi-Lingual 3 languages",
                                                "Hit $1000 Revenue",
                                                "Off Ramp Provider: Rampable"
                                            ]}
                                            bulletIcon={<PagesIndex.Svg.BlackBulletIcon />}
                                            />
                                            
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    PlutoPe App launch on Google Play Store
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Plutope App Launch on Apple Store
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    New Swap Provider: ChangeNow
                                                </Index.Box>
                                            </Index.Box>

                                
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.BlackBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            New On/Off Ramp: Unlimit Crypto
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.BlackBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            500 Downloads
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.BlackBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            Multi-Lingual 3 languages
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.BlackBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            Hit $1000 Revenue
                                                        </Index.Box>
                                                    </Index.Box>
                                                    <Index.Box className="roadmap-bullet">
                                                        <Index.Box className="bullet-icon">
                                                            <PagesIndex.Svg.BlackBulletIcon />
                                                        </Index.Box>
                                                        <Index.Box className="bullet-text">
                                                            Off Ramp Provider: Rampable
                                                        </Index.Box>
                                                    </Index.Box> */}

                                       
                                        </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Typography className='read-more-text' onClick={handleQuaterOne2024Show}>
                                        {isShowQuaterOne2024 ? 'Read Less2' : 'Read More2'}
                                    </Index.Typography> */}
                                </Index.Box>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q2 - (2024)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <ReadMoreReadLessBox items={[
                                                "On/Off Ramp for India: Alpyne",
                                                "On/Off Ramp in 180+ countries: Onramper",
                                                "Swap Provider: RangoExchange",
                                                "5000 Downloads",
                                                "1 million+ Swaps",
                                                "$1 million+ Volume",
                                                "Hit $5000 in Revenue",
                                                "PLT Reward Token Pre-Sale",
                                                "Wallet Connect for Transaction signing",
                                                "Arbitrum Integration",
                                                "Optimism Integration",
                                                "Plutope Wallet Insurance: Provider",
                                                "BRC20 Integration",
                                            ]}
                                            bulletIcon={<PagesIndex.Svg.BlackBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    On/Off Ramp for India: Alpyne
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    On/Off Ramp in 180+ countries: Onramper
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Swap Provider: RangoExchange
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    5000 Downloads
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    1 million+ Swaps
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    $1 million+ Volume
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Hit $5000 in Revenue
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    PLT Reward Token Pre-Sale
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Wallet Connect for Transaction signing
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Arbitrum Integration
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Optimism Integration
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Plutope Wallet Insurance: Provider
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    BRC20 Integration
                                                </Index.Box>
                                            </Index.Box> */}
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q3 - (2024)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <ReadMoreReadLessBox items={[
                                                "Plutope Crypto Card Launch Global",
                                                "New UI/UX for the wallet.",
                                                "Integrating with decentralised applications (Dapps) within the PlutoPe wallet. Enabling users to access and interact with various blockchain-based services.",
                                                "Solana Integration",
                                                "Chain Analysis into the PlutoPe wallet",
                                                "Avalanche Integration",
                                                "Near Integration",
                                                "Account Abstraction",
                                                " Scan and pay (merchant gateway) Implementing a comprehensive merchant gateway within PlutoPe. Allowing merchants to register, accept crypto payments, manage rewards, and handle financial operations",
                                                "Arbitrum Integration",
                                                "Optimism Integration",
                                                "Plutope Wallet Insurance: Provider",
                                                "BRC20 Integration",
                                            ]}
                                            bulletIcon={<PagesIndex.Svg.BlackBulletIcon />}
                                            />
                                            {/* <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Plutope Crypto Card Launch Global
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    New UI/UX for the wallet.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Integrating with decentralised applications (Dapps) within the PlutoPe wallet. Enabling users to access and interact with various blockchain-based services.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Solana Integration
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Chain Analysis into the PlutoPe wallet
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Avalanche Integration
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Near Integration
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Account Abstraction
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    <b>Scan and pay (merchant gateway)</b>
                                                    Implementing a comprehensive merchant gateway within PlutoPe. Allowing merchants to register, accept crypto payments, manage rewards, and handle financial operations
                                                </Index.Box>
                                            </Index.Box> */}

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q4 - (2024)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Integrating the Bitcoin Lightning Network within the PlutoPe wallet.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Browser Extension: Developing a browser extension for PlutoPe to enable seamless integration with web-based crypto services.
                                                </Index.Box>
                                            </Index.Box>

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q1 - (2025)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                        <Index.Box className="bullet-text bullet-text-sdk">SDK's API documentation & Third party implementation.</Index.Box>
                                        {/* <ReadMoreReadLessBox items={[
                                                "SDK and API: Offering Software Development Kits (SDKs) for Android and iOS. Providing comprehensive API documentation for third-party developers. Encouraging third-party integration with PlutoPe",
                                                "Developing PlutoPe’s own SDK Phrase key for enhanced security.",
                                            ]}
                                            bulletIcon={<PagesIndex.Svg.BlackBulletIcon />}
                                            /> */}
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text-title">
                                                    SDK and API: Offering Software Development Kits (SDKs) for Android and iOS. Providing comprehensive API documentation for third-party developers. Encouraging third-party integration with PlutoPe
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Developing PlutoPe’s own SDK Phrase key for enhanced security.
                                                </Index.Box>
                                            </Index.Box>

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Index.Box className="roadmap-card-block is-full glass-bg">
                                    <Index.Typography className='roadmap-title'>
                                        Q2 - (2025)
                                    </Index.Typography>
                                    <Index.Box className="roadmap-content-block">
                                        <Index.Box className="roadmap-bullet-block">
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    NFC Card: Implementing functionalities in NFC (Near Field Communication) cards containing embedded chips. Allowing users to make contactless crypto transactions by tapping the card.
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="roadmap-bullet">
                                                <Index.Box className="bullet-icon">
                                                    <PagesIndex.Svg.BlackBulletIcon />
                                                </Index.Box>
                                                <Index.Box className="bullet-text">
                                                    Hardware Wallet Support for Ledger and Ukis.
                                                </Index.Box>
                                            </Index.Box>

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </SwiperSlide>
                        </Swiper>
                    </Index.Box>

                    <Index.Box className="container">
                        <div className="swiper-custom-pagination"></div>
                    </Index.Box>
                </Index.Box>
            </Index.Box >

        </>
    )
}
