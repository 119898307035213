import React from 'react'

function ConnectWallet() {
  return (
        <svg width="289" height="257" viewBox="0 0 289 257" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4_46)">
<path d="M232.6 192C231.2 192 229.9 192 228.6 192C158.4 192 88.1 192 17.9 192C8.60001 192 2.60001 187.7 0.60001 179.7C-1.69999 170.4 5.10001 160.9 14.7 160.2C15.9 160.1 17.2 160.1 18.4 160.1C102.1 160.1 185.9 160.1 269.6 160C277 160 282.8 162.2 286.3 169C289.5 175.1 288.4 182 283.4 187.3C278.9 192 274.2 196.5 269.6 201.1C253 217.7 236.4 234.3 219.7 250.9C215.1 255.5 209.5 257.2 203.2 255.3C197.4 253.5 193.6 249.5 192.3 243.5C191 237.5 192.7 232.4 197.1 228.1C208.3 217 219.4 205.9 230.5 194.7C231.3 194.2 232.5 193.7 232.6 192Z" fill="black"/>
<path d="M55.4 64.2C56.8 64.2 58.1 64.2 59.5 64.2C129.7 64.2 200 64.2 270.2 64.2C279.5 64.2 285.5 68.5 287.6 76.4C290 85.7 283.2 95.2 273.6 95.9C272.6 96 271.6 96 270.6 96C186.4 96 102.1 96 17.9 96C8.39999 96 2.29999 91.6 0.399988 83.3C-0.900012 77.4 0.999988 72.5 5.09999 68.3C12 61.3 19 54.3 26 47.3C40 33.3 53.9 19.3 67.9 5.4C75.2 -1.9 85.4 -1.8 91.8 5.3C97.7 11.9 97.4 21.3 90.8 27.9C79.9 39 68.7 50 57.7 61C56.9 61.8 55.7 62.4 55.4 64.2Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_4_46">
<rect width="288.1" height="256.2" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default ConnectWallet