import React from 'react'

function GooglePlayStore() {
  return (
    
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_302_3296)">
<path d="M5.23836 0.383825C4.29941 -0.135145 3.19697 -0.123146 2.26402 0.391325L13.1984 10.4783L16.8717 6.80496L5.23836 0.383825ZM1.17059 1.42327C0.903602 1.85974 0.75061 2.35921 0.75061 2.88868V21.1067C0.75061 21.6196 0.890102 22.1086 1.14209 22.5361L12.138 11.5402L1.17059 1.42327ZM21.7419 9.49281L18.2381 7.55942L14.3008 11.4952L19.1261 15.9454L21.7434 14.501C22.6854 13.9791 23.2493 13.0431 23.2493 11.9962C23.2478 10.9492 22.6854 10.0133 21.7419 9.49281ZM13.2404 12.5571L2.21453 23.583C2.693 23.856 3.21797 24 3.74594 24C4.25591 24 4.76888 23.871 5.23836 23.6115L17.7431 16.7104L13.2404 12.5571Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_302_3296">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default GooglePlayStore