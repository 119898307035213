import React from 'react'

export default function BlueBulletIcon() {
  return (
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#2B5AF3"/>
        <path d="M10.5299 15.3926L7.3291 12.1919L7.97064 11.5504L10.5299 14.1096L16.0291 8.61035L16.6706 9.25187L10.5299 15.3926Z" fill="white"/>
        </svg>
    </>
  )
}
