import glass from "./images/png/glassbg.png";
import glassFull from "./images/png/glassbg-full.png";
import chartPie from "./images/png/chartfinal.png";
import chartStacks from "./images/png/chartstacks.png";
import chartStacksTwo from "./images/png/chart-stacks.png";
import chartImg from "./images/png/chartImg.png";
import chartImg2 from "./images/png/chartImg2.png";
import downArrow from "./images/png/downArrow.png";
import audit from "./images/png/audit.png";

const Png = {
  glass,
  glassFull,
  chartPie,
  chartStacks,
  chartStacksTwo,
  chartImg,
  chartImg2,
  downArrow,
  audit,
};

export default Png;
