import React from 'react'
import { Outlet } from 'react-router-dom'
import PagesIndex from '../../../../component/PagesIndex';
// import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const Layout = () => {
  return (
    <>
      <PagesIndex.Header />
      {/* <ParallaxProvider > */}
      <Outlet />
      {/* </ParallaxProvider> */}
      {/* <PagesIndex.Footer /> */}
    </>
  )
}

export default Layout;