import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PagesIndex";
import Paper from "@mui/material/Paper";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import PlutopeAbi from "../../../../component/Connectivity/Abis/plutope.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../component/common/button/Button";
import "./myaccount.css";
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function MyAcount() {
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected, address } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const [totalLockedToken, setTotalLockedToken] = useState(0);
  const [totalUnLockedToken, setTotalUnLockedToken] = useState(0);
  const [totalAvailableToken, setTotalAvailableToken] = useState(0);
  const [userTokensData, setUserTokensData] = useState([]);
  const [userReferralData, setUserReferralData] = useState([]);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referralTokenStatus, setReferralTokenStatus] = useState(false);
  const [phaseTime, setPhaseTime] = useState([]);
  const [openView, setOpenView] = React.useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(6);
  const [pageForUser, setPageForUser] = React.useState(1);
  const [perPageForUser, setPerPageForUser] = React.useState(10);
  const [indexNo, setIndexNo] = React.useState(null);
  const [userIndexNo, setUserIndexNo] = React.useState(null);
  const PlutopeAddress = "0x6fD1cf06c0667D7C71EE2Dc21e84E278737b2a2F";
  const handleOpenView = (data, index, userIndex) => {
    setPage(1);
    setOpenView(true);
    setSelectedItemData(data);
    setCurrentIndex(index);
    setUserIndexNo(userIndex);
    setIndexNo(index);
  };
  const handleCloseView = () => setOpenView(false);
  const getTime = (time) => {
    const timestamp = time;
    const date = new Date(timestamp * 1000);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes}`;
    return formattedDate;
  };

  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(PlutopeAddress, PlutopeAbi, provider);
    try {
      setShowLoader(true);
      const [
        phaseDetails,
        founderDetails,
        advisorDetails,
        referrerDetails,
        phaseTime,
      ] = await Promise.all([
        contract.getPrivateSaleBuyerInfo(address),
        contract.getFounderTokenInfo(address),
        contract.getAdvisorTokenInfo(address),
        contract.getAllReferralDetails(address),
        contract.getPhaseTime(),
      ]);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      let totalPLT = 0;
      let lockPLT = 0;
      let availablePLT = 0;

      const userData = [];
      const userReferralData = [];
      let totalReferralTokens = 0;
      let referralClaimStatus;

      const countTokenDetails = (element, percentage) => {
        const PLTToken = ethers.utils.formatUnits(element.pltToken, 18);
        const remainingToken = ethers.utils.formatUnits(
          element.remainingToken,
          18
        );
        totalPLT += Number(PLTToken);
        lockPLT += Number(remainingToken);
        let availableToken = 0;

        const vestingDuration = Number(element.vestingDuration);
        const lastClaimTime = Number(element.lastClaimTime);
        const releaseInterval = Number(element.releaseInterval);

        if (currentTimestamp > vestingDuration) {
          const availableTime = Math.floor(
            (vestingDuration - lastClaimTime) / releaseInterval
          );
          availableToken = (availableTime * PLTToken) / percentage;
          availablePLT += availableToken;
        } else {
          const availableTime = Math.floor(
            (currentTimestamp - lastClaimTime) / releaseInterval
          );
          if (availableTime > 0) {
            availableToken = (availableTime * PLTToken) / percentage;
            availablePLT += availableToken;
          }
        }

        return { PLTToken, remainingToken, availableToken };
      };

      phaseDetails.forEach((element, index) => {
        const { PLTToken, remainingToken, availableToken } = countTokenDetails(
          element,
          24
        );
        userData.push({
          USDTToken: ethers.utils.formatUnits(element.usdtToken, 18),
          PLTToken: PLTToken,
          remainingToken: remainingToken,
          availableToken: availableToken,
          startTime: Number(element.startTime),
          vestingDuration: Number(element.vestingDuration),
          lastClaimTime: Number(element.lastClaimTime),
          releaseInterval: Number(element.releaseInterval),
          initialCliff: Number(element.initialCliff),
          phaseName: element.phaseName,
          active: element.active,
          claimTokenTime: getTime(
            Number(element.lastClaimTime) + Number(element.releaseInterval)
          ),
          index: index,
        });
      });

      founderDetails.forEach((element, index) => {
        const { PLTToken, remainingToken, availableToken } = countTokenDetails(
          element,
          24
        );
        userData.push({
          PLTToken: PLTToken,
          remainingToken: remainingToken,
          availableToken: availableToken,
          startTime: Number(element.startTime),
          vestingDuration: Number(element.vestingDuration),
          lastClaimTime: Number(element.lastClaimTime),
          releaseInterval: Number(element.releaseInterval),
          initialCliff: Number(element.initialCliff),
          phaseName: element.phaseName,
          claimTokenTime: getTime(
            Number(element.lastClaimTime) + Number(element.releaseInterval)
          ),
          index: index,
        });
      });

      advisorDetails.forEach((element, index) => {
        const { PLTToken, remainingToken, availableToken } = countTokenDetails(
          element,
          24
        );
        userData.push({
          PLTToken: PLTToken,
          remainingToken: remainingToken,
          availableToken: availableToken,
          startTime: Number(element.startTime),
          vestingDuration: Number(element.vestingDuration),
          lastClaimTime: Number(element.lastClaimTime),
          releaseInterval: Number(element.releaseInterval),
          initialCliff: Number(element.initialCliff),
          phaseName: element.phaseName,
          claimTokenTime: getTime(
            Number(element.lastClaimTime) + Number(element.releaseInterval)
          ),

          index: index,
        });
      });

      referrerDetails.forEach((element, index) => {
        const formattedAmount = parseFloat(
          ethers.utils.formatUnits(element.amount, 18)
        );
        totalReferralTokens += formattedAmount;

        if (!element.claim) {
          referralClaimStatus = true;
        }
        userReferralData.push({
          refferAddress: element.refferalAddress,
          amount: ethers.utils.formatUnits(element.amount, 18),
          claim: element.claim,
          claimTime: phaseTime[2],
        });
      });
      setTotalUnLockedToken(totalPLT - lockPLT);
      setTotalLockedToken(lockPLT);
      setUserTokensData(userData);
      setTotalAvailableToken(availablePLT);
      setUserReferralData(userReferralData);
      setTotalReferrals(totalReferralTokens);
      setPhaseTime(phaseTime);
      setReferralTokenStatus(referralClaimStatus);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const claimToken = async (currentIndex, userIndex) => {
    setIndexNo(currentIndex);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(PlutopeAddress, PlutopeAbi, signer);
    try {
      setIsLoading(true);
      const tx = await contract.claimToken(userIndex.toString());
      await tx.wait();
      toast.success("Transaction is successfully completed");
      setIsLoading(false);
      handleCloseView();

      allReadData();
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.error?.data?.message);
    }
  };

  const claimFounderOrAdvisorToken = async (type, currentIndex, userIndex) => {
    setIndexNo(currentIndex);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(PlutopeAddress, PlutopeAbi, signer);
    try {
      setIsLoading(true);
      if (type === "Founders/Team") {
        const tx = await contract.claimFounderToken(userIndex.toString());
        await tx.wait();
        toast.success("Transaction is successfully completed");
      } else {
        const tx = await contract.claimAdvisorToken(userIndex.toString());
        await tx.wait();
        toast.success("Transaction is successfully completed");
      }
      setIsLoading(false);
      handleCloseView();

      allReadData();
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.error?.data?.message);
    }
  };

  const claimReferrerToken = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(PlutopeAddress, PlutopeAbi, signer);
    try {
      const tx = await contract.claimReferralTokens();
      await tx.wait();
      toast.success("Transaction is successfully completed");
      allReadData();
    } catch (error) {
      toast.error(error?.error?.data?.message);
    }
  };
  useEffect(() => {
    allReadData();
    document.body.className = "sticky-glass-bgs";
    return () => {
      document.body.className = "";
    };
  }, [address]);

  const data = () => {
    setTimeout(() => {
      if (!isConnected) {
        navigate("/");
      }
    }, 1000);
  };

  useEffect(() => {
    data();
  }, [isConnected]);

  const totalPages = Math.ceil(userTokensData.length / perPageForUser);
  const paginatedData = userTokensData.slice(
    (pageForUser - 1) * perPageForUser,
    pageForUser * perPageForUser
  );

  const totalPagesForReferral = Math.ceil(
    userReferralData.length / perPageForUser
  );
  const paginatedDataForReferral = userReferralData.slice(
    (pageForUser - 1) * perPageForUser,
    pageForUser * perPageForUser
  );
  const handlePageChange = (newPage) => {
    setPageForUser(newPage);
  };
  return (
    <>
      <Index.Box className="my-account-page">
        <Index.Box className="container">
          <Index.Box className="title-wrapes">
            <Index.Typography component="h1">My Account </Index.Typography>
          </Index.Box>
          <ToastContainer />
          <Index.Box className="stat-box">
            <Index.Box className="stat">
              <Index.Box className="p-currency">
                <PageIndex.Svg.logoSymbol />
              </Index.Box>
              <Index.Box className="text-wrape">
                <Index.Typography component="h6">
                  {Number(totalLockedToken).toFixed(2)}
                </Index.Typography>
                <Index.Typography component="p">Locked PLT</Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="stat unlock">
              <Index.Box className="p-currency">
                <PageIndex.Svg.logoSymbolBlack />
              </Index.Box>
              <Index.Box className="text-wrape">
                <Index.Typography component="h6">
                  {totalUnLockedToken.toFixed(2)}
                </Index.Typography>
                <Index.Typography component="p">UnLocked PLT</Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="stat available">
              <Index.Box className="p-currency">
                <PageIndex.Svg.logoSymbol />
              </Index.Box>
              <Index.Box className="text-wrape">
                <Index.Typography component="h6">
                  {totalAvailableToken.toFixed(2)}
                </Index.Typography>
                <Index.Typography component="p">
                  Available PLT for Claim
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="stat ">
              <Index.Box className="reward-btn-wrapper">
                <Index.Box className="p-currency">
                  <PageIndex.Svg.logoSymbol />
                </Index.Box>
                <Index.Box className="text-wrape">
                  <Index.Typography component="h6">
                    {totalReferrals > 0 ? Number(totalReferrals).toFixed(2) : 0}
                  </Index.Typography>
                  <Index.Typography component="p">
                    Referrals PLT
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              {Math.floor(Date.now() / 1000) > phaseTime[2] &&
              totalReferrals > 0 &&
              referralTokenStatus ? (
                <Index.Box className="card-claim-btn-main">
                  <Button onClick={claimReferrerToken} className="reward-btn">
                    Claim Reward
                  </Button>
                </Index.Box>
              ) : (
                <></>
              )}
            </Index.Box>
          </Index.Box>
          <Index.Box className="table-wrape">
            <Index.TableContainer component={Paper}>
              <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell> </Index.TableCell>
                    <Index.TableCell>Phase Name</Index.TableCell>
                    <Index.TableCell>USDT</Index.TableCell>
                    <Index.TableCell align="right">PLT</Index.TableCell>
                    <Index.TableCell align="right">
                      remaining PLT
                    </Index.TableCell>
                    <Index.TableCell align="right">
                      Available PLT
                    </Index.TableCell>
                    <Index.TableCell align="right">
                      Start Duration
                    </Index.TableCell>
                    <Index.TableCell align="right">
                      Vesting Ends
                    </Index.TableCell>
                    <Index.TableCell align="right">
                      Next Claim Date
                    </Index.TableCell>
                    <Index.TableCell align="right">Action</Index.TableCell>
                    <Index.TableCell align="right">Details</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((user, index) => {
                      const globalIndex =
                        (pageForUser - 1) * perPageForUser + index + 1;
                      return (
                        <Index.TableRow
                          key={globalIndex}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell component="td" scope="row">
                            <Index.Box className="table-icon">
                              <PageIndex.Svg.logoSymbolBlack />
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell component="td" scope="row">
                            {user.phaseName}
                          </Index.TableCell>
                          <Index.TableCell component="td" scope="row">
                            {user.USDTToken}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {Number(user.PLTToken).toFixed(2)}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {Number(user.remainingToken).toFixed(2)}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {Number(user?.availableToken).toFixed(2)}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {getTime(user.startTime)}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {getTime(user.vestingDuration)}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            {user.lastClaimTime === user.vestingDuration
                              ? "Completed"
                              : user.claimTokenTime}
                          </Index.TableCell>
                          <Index.TableCell align="right" component="td">
                            <Index.Box className="view-content">
                              {isLoading && globalIndex === indexNo ? (
                                <Index.Box>
                                  <span className="loader1"></span>
                                </Index.Box>
                              ) : Math.floor(Date.now() / 1000) >
                                  Number(user?.lastClaimTime) +
                                    Number(user?.releaseInterval) &&
                                user?.lastClaimTime < user?.vestingDuration ? (
                                <Button
                                  className="view-content-btn"
                                  variant="outlined"
                                  onClick={() => {
                                    user?.phaseName === "Founders/Team" ||
                                    user?.phaseName === "Advisors"
                                      ? claimFounderOrAdvisorToken(
                                          user?.phaseName,
                                          globalIndex,
                                          user.index
                                        )
                                      : claimToken(globalIndex, user.index);
                                  }}
                                >
                                  CLAIM
                                </Button>
                              ) : user.lastClaimTime ===
                                user.vestingDuration ? (
                                <span
                                  style={{ color: "green", marginLeft: "20px" }}
                                >
                                  Claimed
                                </span>
                              ) : (
                                <></>
                              )}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="right"
                            className="actions"
                            onClick={() =>
                              handleOpenView(user, globalIndex, user.index)
                            }
                          >
                            <Index.Box className="table-btn">
                              <PageIndex.Svg.ViewIcon />
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell colSpan={18} align="center">
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
              {totalPages > 1 ? (
                <Index.Box className="claim-flex">
                  <Index.Box sx={{ flexGrow: "1" }}></Index.Box>
                  <Index.Box className="claim-right-main">
                    <Button
                      className="view-content-btn"
                      onClick={() => {
                        if (pageForUser > 1) {
                          setPageForUser(pageForUser - 1);
                        }
                      }}
                    >
                      Prev
                    </Button>

                    <Index.Typography
                      sx={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Page {pageForUser}
                    </Index.Typography>
                    <Button
                      className="view-content-btn"
                      onClick={() => {
                        if (pageForUser < totalPages) {
                          handlePageChange(pageForUser + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  </Index.Box>
                </Index.Box>
              ) : (
                <></>
              )}
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {userReferralData.length > 0 ? (
        <Index.Box className="my-account-page">
          <Index.Box className="container">
            <Index.Box className="title-wrapes">
              <Index.Typography component="h1">
                Referral History
              </Index.Typography>
            </Index.Box>
            <ToastContainer />
            {/* <Index.Box className="stat-box new-state-box">
            <Index.Box className="stat">
              <Index.Box className="reward-btn-wrapper">
                  <Index.Box className="p-currency">
                    <PageIndex.Svg.logoSymbol />
                  </Index.Box>
                  <Index.Box className="text-wrape">
                    <Index.Typography component="h6">
                      {totalReferrals >0 ? Number(totalReferrals).toFixed(2) : 0}
                    </Index.Typography>
                    <Index.Typography component="p">Referrals PLT</Index.Typography>
                  </Index.Box>
              </Index.Box>
              <Index.Box>
                {Math.floor(Date.now() / 1000) > phaseTime[2] && totalReferrals > 0 ? (
                  <Index.Box className="text-wrape">
                  <Button onClick={claimReferrerToken} className="reward-btn">Claim Reward</Button>
               </Index.Box> 
                ): (<></>)}
              </Index.Box>
              </Index.Box>
          </Index.Box> */}
            <Index.Box className="table-wrape">
              <Index.TableContainer component={Paper}>
                <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell> </Index.TableCell>
                      <Index.TableCell>Referral Address</Index.TableCell>
                      <Index.TableCell>Referral Tokens</Index.TableCell>
                      {/* <Index.TableCell align="right">Claim Time</Index.TableCell> */}
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {paginatedDataForReferral.map((user, index) => {
                      return (
                        <Index.TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell component="td" scope="row">
                            <Index.Box className="table-icon">
                              <PageIndex.Svg.logoSymbolBlack />
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell component="td" scope="row">
                            {user?.refferAddress?.slice(0, 6) +
                              "..." +
                              user?.refferAddress?.slice(-4)}
                          </Index.TableCell>
                          <Index.TableCell component="td" scope="row">
                            {Number(user.amount).toFixed(2)}
                          </Index.TableCell>
                          {/* <Index.TableCell align="right" component="td">
                                  {getTime(user.claimTime)} 
                                </Index.TableCell> */}
                        </Index.TableRow>
                      );
                    })}
                  </Index.TableBody>
                </Index.Table>
                <Index.Box className="claim-flex">
                  {totalPagesForReferral > 1 ? (
                    <>
                      <Index.Box sx={{ flexGrow: "1" }}></Index.Box>
                      <Index.Box className="claim-right-main">
                        <Button
                          className="view-content-btn"
                          onClick={() => {
                            if (pageForUser > 1) {
                              setPageForUser(pageForUser - 1);
                            }
                          }}
                        >
                          Prev
                        </Button>

                        <Index.Typography
                          sx={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          Page {pageForUser}
                        </Index.Typography>
                        <Button
                          className="view-content-btn"
                          onClick={() => {
                            if (pageForUser < totalPagesForReferral) {
                              handlePageChange(pageForUser + 1);
                            }
                          }}
                        >
                          Next
                        </Button>
                      </Index.Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Index.Box>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <></>
      )}

      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-wrape view-detail"
      >
        <Index.Box sx={style} className="modal-pad modal-containt-main">
          <Index.Box className="modal-head">
            View Detail
            <Index.Link onClick={handleCloseView} className="modal-close">
              <PageIndex.Svg.ModalCloseIcon />
            </Index.Link>
          </Index.Box>
          <Index.Box className="modal-body view-body-content">
            <Index.Box className="account-data-ul">
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  USDT:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData?.phaseName === "Founders/Team" ||
                  selectedItemData?.phaseName === "Advisors"
                    ? 0
                    : Number(selectedItemData?.USDTToken).toFixed(2)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  PLT:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData
                    ? Number(selectedItemData.PLTToken).toFixed(2)
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  Available Token:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData
                    ? Number(selectedItemData.availableToken).toFixed(2)
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Box className="claim-btn-flex">
                  {isLoading && currentIndex === indexNo ? (
                    <Index.Box>
                      <span class="loader1"></span>
                    </Index.Box>
                  ) : Math.floor(Date.now() / 1000) >
                      Number(selectedItemData?.lastClaimTime) +
                        Number(selectedItemData?.releaseInterval) &&
                    selectedItemData?.lastClaimTime <
                      selectedItemData?.vestingDuration ? (
                    <Button
                      className="view-content-btn"
                      onClick={() => {
                        console.log(currentIndex, indexNo, "currentIndex");
                        console.log(
                          selectedItemData?.index,
                          "selectedItemData?.index"
                        );
                        selectedItemData?.phaseName === "Founders/Team" ||
                        selectedItemData?.phaseName === "Advisors"
                          ? claimFounderOrAdvisorToken(
                              selectedItemData?.phaseName,
                              currentIndex,
                              selectedItemData?.index
                            )
                          : claimToken(currentIndex, selectedItemData?.index);
                      }}
                    >
                      CLAIM
                    </Button>
                  ) : (
                    <></>
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  Vesting Start:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData ? getTime(selectedItemData.startTime) : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  Vesting Ends:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData
                    ? getTime(selectedItemData.vestingDuration)
                    : 0}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-wrapper">
            <Index.Box className="view-flex">
              <Index.Box className="view-content">Release Token</Index.Box>
              <Index.Box className="view-content">Release Date</Index.Box>
              <Index.Box className="view-content">Status</Index.Box>
            </Index.Box>
          </Index.Box>

          {Array.from(
            {
              length: 24,
            },
            (_, index) => {
              if (index >= (page - 1) * perPage && index < page * perPage) {
                const founder_Advisor =
                  selectedItemData?.phaseName === "Founders/Team" ||
                  selectedItemData?.phaseName === "Advisors"
                    ? "Founders/Team"
                    : "Presale";

                const completed =
                  founder_Advisor === "Presale"
                    ? Number(
                        selectedItemData?.lastClaimTime -
                          Number(
                            selectedItemData?.startTime +
                              selectedItemData?.initialCliff
                          )
                      ) / Number(selectedItemData?.releaseInterval)
                    : Number(
                        selectedItemData?.lastClaimTime -
                          Number(
                            selectedItemData?.startTime +
                              selectedItemData?.initialCliff
                          )
                      ) / Number(selectedItemData?.releaseInterval);
                let available =
                  (Math.floor(Date.now() / 1000) -
                    Number(selectedItemData?.lastClaimTime)) /
                    Number(selectedItemData?.releaseInterval) +
                  completed;

                const alertVariant =
                  index + 1 <= completed
                    ? "success"
                    : index + 1 <= available
                    ? "info"
                    : "warning";
                const alertText =
                  index + 1 <= completed
                    ? "Completed"
                    : index + 1 <= available
                    ? "Available"
                    : "Coming Soon";

                return (
                  <Alert
                    key={index}
                    variant="filled"
                    severity={alertVariant}
                    icon={false}
                    className="alert-wrape view-alert-box"
                    sx={{
                      padding: "10px",
                      background:
                        alertVariant === "success"
                          ? "#75d57a"
                          : alertVariant === "info"
                          ? "#7bb4d3"
                          : "#e79654",
                      textAlign: "center",
                      margin: "4px 10px",
                      fontSize: "10px",
                    }}
                  >
                    <Index.Box className="view-flex">
                      <Index.Box className="view-content">
                        {Number(selectedItemData?.PLTToken / 24).toFixed(2)}
                        PLT
                      </Index.Box>
                      <Index.Box className="view-content">
                        {getTime(
                          founder_Advisor === "Presale"
                            ? String(
                                Number(selectedItemData?.startTime) +
                                  Number(selectedItemData?.releaseInterval) *
                                    (index + 1) +
                                  Number(selectedItemData?.initialCliff)
                              )
                            : String(
                                Number(selectedItemData?.startTime) +
                                  Number(selectedItemData?.releaseInterval) *
                                    (index + 1) +
                                  Number(selectedItemData?.initialCliff)
                              )
                        )}
                      </Index.Box>
                      <Index.Box className="view-content">
                        {alertText}
                      </Index.Box>
                    </Index.Box>
                  </Alert>
                );
              }
            }
          )}
          <Index.Box className="claim-flex">
            {/* <Index.Box className="claim-left-main">
                {isLoading ? (
                  <Index.Box
                    // style={{ "margin-right": "13px", "margin-left": "122px" }}
                  >
                    <span class="loader"></span>
                  </Index.Box>
                ) : Math.floor(Date.now() / 1000) >
                    Number(selectedItemData?.lastClaimTime) +
                      Number(selectedItemData?.releaseInterval) &&
                  selectedItemData?.lastClaimTime <
                    selectedItemData?.vestingDuration ? (
                  <Button
                    className="view-content-btn"
                    onClick={() => {
                      selectedItemData?.phaseName === "Founders/Team" ||
                      selectedItemData?.phaseName === "Advisors"
                        ? claimFounderOrAdvisorToken(
                            selectedItemData?.phaseName,
                            currentIndex
                          )
                        : claimToken(currentIndex);
                    }}
                  >
                    CLAIM
                  </Button>
                ) : (
                  <></>
                )}
            </Index.Box> */}
            <Index.Box className="claim-right-main">
              <Button
                className="view-content-btn"
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                Prev
              </Button>

              <Index.Typography>Page {page}</Index.Typography>
              <Button
                className="view-content-btn"
                onClick={() => {
                  if (page < 4) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
