import React from 'react'

function Insta() {
  return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_23_6)">
          <mask id="mask0_23_6" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <path d="M24 0H0V24H24V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_23_6)">
          <path d="M17.9999 0H6.00016C2.70053 0 0.000305176 2.70023 0.000305176 5.99985V18.0001C0.000305176 21.2989 2.70053 24 6.00016 24H17.9999C21.2995 24 23.9997 21.2989 23.9997 18.0001V5.99985C23.9997 2.70023 21.2995 0 17.9999 0ZM21.9997 18.0001C21.9997 20.2051 20.206 22 17.9999 22H6.00016C3.79492 22 2.00035 20.2051 2.00035 18.0001V5.99985C2.00035 3.79432 3.79492 2.00005 6.00016 2.00005H17.9999C20.206 2.00005 21.9997 3.79432 21.9997 5.99985V18.0001Z" fill="black"/>
          <path d="M18.5008 6.99993C19.3292 6.99993 20.0008 6.32837 20.0008 5.49996C20.0008 4.67156 19.3292 4 18.5008 4C17.6724 4 17.0009 4.67156 17.0009 5.49996C17.0009 6.32837 17.6724 6.99993 18.5008 6.99993Z" fill="black"/>
          <path d="M12 6C8.68569 6 6.00017 8.68581 6.00017 11.9999C6.00017 15.3127 8.68569 18.0003 12 18.0003C15.3134 18.0003 17.9999 15.3127 17.9999 11.9999C17.9999 8.68581 15.3134 6 12 6ZM12 16.0002C9.79115 16.0002 8.00022 14.2093 8.00022 11.9999C8.00022 9.7904 9.79115 8.00005 12 8.00005C14.2089 8.00005 15.9998 9.7904 15.9998 11.9999C15.9998 14.2093 14.2089 16.0002 12 16.0002Z" fill="black"/>
          </g>
          </g>
          <defs>
          <clipPath id="clip0_23_6">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
          </defs>
          </svg>
  )
}

export default Insta