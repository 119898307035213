import React from 'react'

export default function BlackBulletIcon() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_2)">
        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="black"/>
        <path d="M10.5299 15.3926L7.3291 12.1919L7.97064 11.5504L10.5299 14.1096L16.0291 8.61035L16.6706 9.25187L10.5299 15.3926Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_2_2">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </>
  )
}
