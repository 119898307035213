import Routes from "./routes/Routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./assets/style/style.css";
import "./assets/style/newStyle.css";
import "./component/user/defaulLayout/defaultLayout.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/style/responsive.css";
import "./assets/style/newResponsive.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";

const bscTestnet = {
  chainId: 97,
  name: "BSC TestNet",
  currency: "TBNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
};

const BNBSmartChainMainnet = {
  chainId: 56,
  name: "BNB Smart Chain Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.binance.org/",
};

const metadata = {
  name: "Plutope Presale",
  description: "presale for  plutope token ",
  url: "https://presale.plutope.io/",
  icons: ["https://presale.plutope.io/favicon.ico"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [BNBSmartChainMainnet],
  projectId,
  enableAnalytics: true,
  featuredWalletIds: [
    "91b9a02134bc834b96ebfa333a0bf27ac6372ac56682b5e77bf8577abe0eea78",
  ],
});

function App() {
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Inter, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  });
  // console.log = () => {};
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
