import React from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

export default function Footer() {
  const handleNavigate = (sectionName) => {
    const section = document.getElementById(`${sectionName}`);

    if (section) {
      const sectionTop = section.offsetTop;
      window.scrollTo({
        top: sectionTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Index.Box component="footer" className="footer-wrape">
        <Index.Box className="container">
          <Index.Box className="footer-up">
            <Index.Typography component="h2">
              ONE APP DECENTRALIZED TO CORE
            </Index.Typography>

            <Index.Box className="store-wrape">
              <Index.Typography component="p">Download app</Index.Typography>
              <Index.Box>
                <Index.Link
                  to="https://apps.apple.com/in/app/plutope-crypto-wallet/id6466782831"
                  target="_blank"
                >
                  <Index.Button type="submit" className="border-btn">
                    <Index.Box component="span" className="icon">
                      <PagesIndex.Svg.AppStore />
                    </Index.Box>{" "}
                    Apple Store
                  </Index.Button>
                </Index.Link>
                <Index.Link
                  to="https://play.google.com/store/apps/details?id=com.app.plutope&pli=1"
                  target="_blank"
                >
                  <Index.Button type="submit" className="border-btn">
                    <Index.Box component="span" className="icon">
                      <PagesIndex.Svg.GooglePlayStore />
                    </Index.Box>{" "}
                    Google Play
                  </Index.Button>
                </Index.Link>
              </Index.Box>
              <Index.Box className="audit-img-wrape">
                <img
                  src={PagesIndex.Png.audit}
                  alt="audit"
                  className="audit-img"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="footer-down">
            <Index.Box className="footer-brand">
              <Index.Link to="" className="brand-logo">
                <img src={PagesIndex.Svg.logoWhite} />
              </Index.Link>
            </Index.Box>
            <Index.Box className="footer-menus">
              <Index.Typography component="ul">
                <Index.Typography component="li">
                  <Index.Typography component="h6">
                    QUICK LINKS
                  </Index.Typography>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link to="/">Home</Index.Link>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link
                    to="https://www.plutope.io/#section-about"
                    target="_blank"
                  >
                    About
                  </Index.Link>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link to="https://www.plutope.io/blogs" target="_blank">
                    Blog
                  </Index.Link>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link
                    to="https://www.plutope.io/contact-us"
                    target="_blank"
                  >
                    Contact
                  </Index.Link>
                </Index.Typography>
              </Index.Typography>
              <Index.Typography component="ul">
                <Index.Typography component="li">
                  <Index.Typography component="h6">CARDS</Index.Typography>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link
                    to="https://www.plutope.io/debit-cards#stack-card"
                    target="_blank"
                  >
                    Stack Card
                  </Index.Link>
                </Index.Typography>
                {/* <Index.Typography component="li">
                  <Index.Link to="https://www.plutope.io/blogs"  target='_blank'>
                    About
                  </Index.Link>
                </Index.Typography> */}
                <Index.Typography component="li">
                  <Index.Link
                    to="https://www.plutope.io/debit-cards#game-card"
                    target="_blank"
                  >
                    Game Card
                  </Index.Link>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Link
                    to="https://www.plutope.io/debit-cards#infinite-card"
                    target="_blank"
                  >
                    Infinite Card
                  </Index.Link>
                </Index.Typography>
              </Index.Typography>
              <Index.Typography component="ul">
                <Index.Typography component="li">
                  <Index.Box component="h6">Contact</Index.Box>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Typography component="p">
                    <Index.Typography component="b"> EMAIL:</Index.Typography>
                    <Index.Link to="mailto:HEY@PLUTOPE.IO">
                      {" "}
                      HEY@PLUTOPE.IO
                    </Index.Link>
                  </Index.Typography>
                </Index.Typography>
                {/* <Index.Typography component="li">
                  <Index.Typography component="p">
                    <Index.Typography component="b"> PHONE:</Index.Typography>
                    <Index.Link to="tel:+91 85274-82283"> +91 85274-82283</Index.Link>
                  </Index.Typography>
                </Index.Typography> */}
                <Index.Typography component="li">
                  <Index.Typography component="p">
                    <Index.Typography component="b">
                      {" "}
                      LOCATION:
                    </Index.Typography>
                    <Index.Link to=""> GURGAON, HARYANA</Index.Link>
                  </Index.Typography>
                </Index.Typography>
                <Index.Typography component="li">
                  <Index.Typography component="p">
                    {/* <Index.Typography component="b"> SUPPORT:</Index.Typography> */}
                    <Index.Link
                      to="https://t.me/PlutoPeOfficial"
                      className="support-link"
                    >
                      SUPPORT
                    </Index.Link>
                  </Index.Typography>
                </Index.Typography>
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="footer-copyright">
            <Index.Box className="bottom-link-wrape">
              <Index.Typography component="p" className="copyright">
                © 2024 PLUTOPE PVT. LTD.
              </Index.Typography>
              <Index.Link
                to="https://www.plutope.io/privacy-policy"
                target="_blank"
              >
                PRIVACY POLICY
              </Index.Link>
              <Index.Link
                to="https://www.plutope.io/terms-and-conditions"
                target="_blank"
              >
                TERMS OF USE
              </Index.Link>
              <Index.Link
                to="https://www.plutope.io/kyc-aml-policy"
                target="_blank"
              >
                KYC/AML POLICY
              </Index.Link>
              <Index.Link
                to="https://cdn.prod.website-files.com/6527c1b34fea32f3c88ebc27/66bb19178ec355e79dfb2560_PlutoPe%20KYC%20Certificate.pdf"
                target="_blank"
              >
                TEAM KYC
              </Index.Link>
            </Index.Box>

            <Index.Box className="header-right-main social-media-wrape">
              <Index.Link
                to="https://t.me/plutopeofficial"
                className="icon fb"
                target="_blank"
              >
                <PagesIndex.Svg.Telegram />
              </Index.Link>
              <Index.Link
                to="https://discord.com/invite/yXgfQ8Kpwn"
                className="icon twit"
                target="_blank"
              >
                <PagesIndex.Svg.Discord />
              </Index.Link>
              <Index.Link
                to="https://twitter.com/Plutopeio?t=3teWOyzUBrIlqfra6vkeFA&s=09"
                className="icon insta"
                target="_blank"
              >
                <PagesIndex.Svg.Twit />
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
