import React from 'react'

export default function NextArrow() {
  return (
    <>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8461 17.6534L12.7923 16.5688L16.6116 12.7495H4.5V11.2496H16.6116L12.7923 7.4303L13.8461 6.3457L19.5 11.9995L13.8461 17.6534Z" fill="black"></path>
        </svg>
    </>
  )
}
