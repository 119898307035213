
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Jpg from "../assets/jpg";
import Video from "../assets/Video";

// Component

import Header from "./user/defaulLayout/Header";
import NewHeader from "./user/defaulLayout/NewHeader";
import Footer from "./user/defaulLayout/Footer";

import OpenLabel from "../component/common/label/OpenLabel";
import CloseLabel from "../component/common/label/CloseLabel";

import Button from "./common/button/Button";
import DonuteChart from './common/charts/DonuteChart';
import PieChart from './common/charts/PieChart';

import RoadMap from './common/roadMap/RoadMap';
import SignUpModal from './common/modal/SignUpModal';

import Faq from './common/faq/Faq';

import HowToBuy from './common/howtobuy/HowToBuy';
import Hero from './common/hero/Hero';
import HeroTwo from './common/hero/HeroTwo';
import Table from './common/table/Table';

export default {
      Svg,
      Png,
      Jpg,
      Header,
      Footer,
      OpenLabel,
      CloseLabel,
      Button,
      DonuteChart,
      RoadMap,
      SignUpModal,
      Faq,
      HowToBuy,
      Hero,
      Table,
      HeroTwo,
      Video,
      PieChart,
      NewHeader,
};
