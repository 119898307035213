import React from 'react'

function Bnb() {
  return (
<svg width="197" height="188" viewBox="0 0 197 188" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M99.4 166.6C56.7 166.6 22.2 132 22.2 89.3C22.2 46.6 56.8 12.1 99.5 12.1C142.1 12.1 176.6 46.7 176.6 89.4C176.6 132 142 166.6 99.4 166.6Z" fill="#F1B92F"/>
<path d="M129 70.5C129.1 70.8 128.9 70.9 128.8 71.1C125.4 74.5 121.9 78 118.5 81.4C118.4 81.5 118.3 81.5 118.1 81.6C117.8 81.5 117.6 81.2 117.3 81C111.6 75.3 105.8 69.5 100.1 63.8C99.8 63.5 99.6 63 99.1 63.3C96.3 66.1 93.5 68.9 90.7 71.7C87.5 74.9 84.3 78.1 81.2 81.2C81 81.4 80.9 81.5 80.7 81.6C80.5 81.6 80.3 81.4 80.2 81.2C76.9 77.9 73.6 74.6 70.3 71.3C70.2 71.2 70 71 69.9 70.8C69.8 70.3 70.2 70.1 70.5 69.9C78.6 61.8 86.7 53.7 94.7 45.6C96 44.3 97.4 42.9 98.7 41.6C98.9 41.4 99.1 41.1 99.4 41C99.8 40.9 99.9 41.3 100.1 41.5C108.8 50.2 117.5 58.9 126.2 67.6C126.4 67.8 126.6 67.9 126.8 68.1C127.5 68.9 128.3 69.7 129 70.5Z" fill="#FEFEFE"/>
<path d="M118.1 97.1C118.4 97.1 118.6 97.4 118.8 97.6C122.1 100.9 125.3 104.1 128.6 107.4C129.1 107.9 129 108.1 128.6 108.6C119.1 118.1 109.6 127.6 100.1 137.1C99.6 137.6 99.4 137.6 98.9 137.1C89.4 127.6 79.9 118.1 70.4 108.6C69.9 108.1 69.9 107.9 70.4 107.4C73.7 104.2 77 100.9 80.2 97.6C80.7 97.1 80.9 97.2 81.4 97.6C87.2 103.4 92.9 109.1 98.7 114.9C98.9 115.1 99.1 115.2 99.3 115.4C99.8 115.7 100 115.3 100.3 115C104.2 111.1 108.1 107.2 112 103.4C113.9 101.5 115.8 99.6 117.7 97.7C117.6 97.5 117.8 97.2 118.1 97.1Z" fill="#FEFEFE"/>
<path d="M110.4 89.3C110.4 89.6 110.2 89.7 110.1 89.8C106.7 93.2 103.3 96.6 99.9 100C99.5 100.4 99.3 100.4 99 100C95.6 96.6 92.2 93.2 88.8 89.8C88.4 89.4 88.4 89.2 88.8 88.9C92.2 85.5 95.6 82.1 99 78.7C99.4 78.3 99.6 78.4 99.9 78.7C103.3 82.1 106.7 85.5 110.1 88.9C110.2 89 110.3 89.2 110.4 89.3Z" fill="#FEFEFE"/>
<path d="M61.9 78.5C64 80.4 66 82.5 68 84.5C69.5 86 71 87.5 72.5 89C72.7 89.2 73 89.4 72.6 89.8C69.1 93.3 65.6 96.9 62 100.4C59.8 98.2 57.7 96.1 55.6 93.9C54.3 92.6 52.9 91.2 51.6 89.9C51.4 89.7 51.1 89.6 51.2 89.2C51.3 89 51.5 88.8 51.6 88.7C54.9 85.4 58.1 82.1 61.4 78.9C61.6 78.7 61.7 78.5 61.9 78.5Z" fill="#FEFEFE"/>
<path d="M136.8 78.5C138.4 79.9 139.9 81.5 141.4 83C143.4 85 145.4 87 147.3 88.9C147.6 89.2 147.7 89.4 147.4 89.8C143.9 93.2 140.5 96.7 137.1 100.1C137 100.2 136.9 100.2 136.8 100.3C136.6 100.3 136.5 100.1 136.3 100C133 96.7 129.8 93.5 126.5 90.2C126.4 90.1 126.2 89.9 126.1 89.7C126 89.5 125.9 89.4 125.9 89.2C126 89 126.2 88.8 126.3 88.7C129.6 85.4 132.8 82.1 136.1 78.9C136.4 78.7 136.6 78.5 136.8 78.5Z" fill="#FEFEFE"/>
<path d="M99.3 41.1C99.1 41.3 98.9 41.6 98.7 41.8C89.2 51.2 79.8 60.6 70.4 70C70.2 70.2 69.8 70.4 69.8 70.8C69.8 70.9 69.7 70.9 69.7 70.8C69.5 70.7 69.6 70.5 69.7 70.4C69.9 70.2 70.1 70 70.3 69.7C79.7 60.3 89.1 50.9 98.5 41.5C98.7 41.4 98.8 40.9 99.3 41.1Z" fill="#F2B624"/>
<path d="M99.1 63.3C99.4 62.7 99.6 63.1 99.9 63.4C102.1 65.6 104.3 67.8 106.5 70C110.4 73.9 114.3 77.8 118.2 81.7C117.8 81.9 117.7 81.5 117.5 81.3C111.7 75.5 105.9 69.8 100.2 64C99.8 63.6 99.5 63.3 99.1 63.3Z" fill="#F2B624"/>
<path d="M118.1 97.1C113.9 101.3 109.7 105.5 105.6 109.7C103.7 111.6 101.8 113.5 99.9 115.5C99.6 115.8 99.4 116 99.2 115.5C99.6 115.5 99.9 115.2 100.2 114.9C106 109.1 111.8 103.3 117.5 97.6C117.6 97.4 117.7 97 118.1 97.1Z" fill="#F2B624"/>
<path d="M69.7 70.9H69.8C71.9 73 74 75.1 76.2 77.2C77.7 78.7 79.2 80.2 80.6 81.7C80.4 82 80.2 81.7 80.1 81.6C76.7 78.2 73.3 74.8 70 71.5C69.8 71.2 69.6 71.1 69.7 70.9Z" fill="#F2B624"/>
<path d="M61.9 78.5C58.3 82.1 54.7 85.7 51.1 89.2C50.9 88.9 51.2 88.9 51.3 88.8C54.7 85.4 58.1 82 61.4 78.7C61.6 78.5 61.7 78.2 61.9 78.5Z" fill="#F2B624"/>
<path d="M136.8 78.5C133.2 82.1 129.6 85.7 126 89.2C125.8 88.9 126.1 88.9 126.2 88.8C129.6 85.4 133 82 136.3 78.7C136.5 78.5 136.5 78.2 136.8 78.5Z" fill="#F2B624"/>
<path d="M126.2 89.7C129 92.4 131.7 95.2 134.4 97.9C135.2 98.7 136 99.5 136.7 100.3C136.4 100.6 136.4 100.2 136.3 100.1C133 96.8 129.6 93.5 126.3 90.1C126.3 90 126 89.9 126.2 89.7Z" fill="#F2B624"/>
<path d="M129 70.5C128.2 69.7 127.4 68.9 126.7 68.2C127 68 127.1 68.3 127.2 68.4C127.8 69.1 128.7 69.6 129 70.5Z" fill="#F2B623"/>
</svg>
  )
}

export default Bnb