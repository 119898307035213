import React from 'react'

export default function GreenBulletIcon() {
  return (
    <>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12.3999" r="12" fill="#38FF8E"/>
            <path d="M10.53 15.7923L7.32922 12.5915L7.97076 11.95L10.53 14.5092L16.0292 9.01001L16.6707 9.65153L10.53 15.7923Z" fill="black"/>
        </svg>
    </>
  )
}
