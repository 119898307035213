import React from 'react'

function Unlock() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="10.415" height="13.879" viewBox="0 0 10.415 13.879">
  <g id="unlock" transform="translate(-7 -4.013)">
    <path id="Path_5268" data-name="Path 5268" d="M16.258,9.213H9.893V7.477a2.314,2.314,0,1,1,4.629,0,.58.58,0,0,0,.579.579c1.29-.156.168-2.54-.44-3.038A3.49,3.49,0,0,0,8.736,7.477V9.213H8.157A1.161,1.161,0,0,0,7,10.37v6.365a1.161,1.161,0,0,0,1.157,1.157h8.1a1.161,1.161,0,0,0,1.157-1.157V10.37a1.161,1.161,0,0,0-1.157-1.157ZM12.786,13.6v1a.579.579,0,0,1-1.157,0v-1a.934.934,0,1,1,1.157,0Z" fill="#000000"/>
  </g>
</svg>
  )
}

export default Unlock