import React from 'react'
// import Index from "../../../Index";
// import PageIndex from "../../../PagesIndex";
import Index from '../../../container/Index';
import PageIndex from '../../../container/PagesIndex';
import { motion } from "framer-motion"
import { Parallax, ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { ScrollTrigger } from "gsap/ScrollTrigger";




function NewChart() {

  return (
    <>

      <Index.Box className="frame chart new-chart-sec">
        <Index.Box className="chart-wrape">
          <Index.Box className="head-section">
            <Index.Box className="title-wrape">
              <Index.Typography component="p" className="head-label tokenomic-title">
                Tokenomics
              </Index.Typography>
              <Index.Typography component="h4">
                Stack wise Display!!
              </Index.Typography>
              {/* <Index.Typography component="p">
                It is a long established fact that a reader will be
                distracted.
              </Index.Typography> */}
            </Index.Box>
          </Index.Box>

          <Index.Box className="chart-section">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-form"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 5",
                  lg: "span 5",
                }}
                className=""
              >
                {/* <motion.div
                  animate={{ x: -200, opacity: 0, }}
                  transition={{ duration: 0.8 }}
                  whileInView={{ x: 0, opacity: 1, }}> */}
                <Index.Box className="chart-img-section">
                  {/* <Parallax 
                  translateX={[-100, 0]}
                  translateY={[0,0]}
                  ref={ref}
                  > */}
                  <ParallaxProvider>
                    <Parallax
                      translateX={[-106, 10]} 
                    >
                      <img src={PageIndex.Png.chartImg2} alt="chartImg" className="chart-img" />
                    </Parallax>
                  </ParallaxProvider>
                  {/* </Parallax> */}
                </Index.Box>
                {/* </motion.div> */}
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 7",
                  lg: "span 7",
                }}
                className="chart-detail-col"
              >
                 <ParallaxProvider>
                    <Parallax
                      translateX={[110, 10]} 
                    >
                <Index.Box className="chart-detail-section">
                  <Index.Box className="detail-col detail-col-1">
               
                      <Index.Box>
                        <Index.Box className="detail-wrapper partnership-text">
                          <Index.Typography component="P">Partnership / Exchanges :</Index.Typography><span>18%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper tresury-text">
                          <Index.Typography component="P">Treasury : </Index.Typography><span>18.2%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper advisors-text">
                          <Index.Typography component="P">Advisors : </Index.Typography><span>2.5%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper ecosystem-text">
                          <Index.Typography component="P">Ecosystem : </Index.Typography><span>20%</span>
                        </Index.Box>
                      </Index.Box>
               
                  </Index.Box>
                  <Index.Box className="detail-col detail-col-2">
                      <Index.Box>
                        <Index.Box className="detail-wrapper founder-text">
                          <Index.Typography component="P">Founder/Team : </Index.Typography><span>12%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper pre-seed-text">
                          <Index.Typography component="P">Pre Seed : </Index.Typography><span>5%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper seed-text">
                          <Index.Typography component="P">Seed : </Index.Typography><span>13.33%</span>
                        </Index.Box>
                        <Index.Box className="detail-wrapper strategic-text">
                          <Index.Typography component="P">Strategic : </Index.Typography><span>6%</span>
                        </Index.Box>
                      </Index.Box>
                  </Index.Box>
                </Index.Box>
                </Parallax>
                </ParallaxProvider>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="chart-img-section text-center">
                    <img src={PageIndex.Png.chartImg2} alt="chartImg" />
                  </Index.Box> */}
          </Index.Box>

        </Index.Box>

        {/* <Index.Box className="vdo-frame">
          <video
            className="presale-video"
            controls="false"
            controls
            autoPlay="true"
            muted
            loop
            playsinline=""
            data-wf-ignore="true"
            data-object-fit="cover"
          >
            <source
              src={PageIndex.Video.videoTwo}
              type="video/mp4"
              alt="banner video"
            />
          </video>
        </Index.Box> */}
      </Index.Box>
    </>
  )
}

export default NewChart
