import React from 'react'

function BrandSymbol() {
  return (
        <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg" id="bran-symbol">
<path d="M5.8 22.33L2.99 18.25C2.48117 17.5218 2.25702 16.6323 2.35999 15.75C2.36281 15.6172 2.39709 15.487 2.46 15.37L6.25999 11.76L10.19 11.54C10.6392 11.5109 11.0717 11.3587 11.44 11.1C11.8337 10.7775 12.1716 10.3924 12.44 9.95999C12.85 9.40999 13.25 8.83999 13.64 8.26999C13.942 7.87942 14.2002 7.45689 14.41 7.00999C14.4346 6.96202 14.4474 6.90889 14.4474 6.85499C14.4474 6.80109 14.4346 6.74797 14.41 6.7C14.3842 6.66438 14.3514 6.63445 14.3135 6.61208C14.2757 6.58971 14.2336 6.57539 14.19 6.56999C13.8807 6.54002 13.5693 6.54002 13.26 6.56999C12.93 6.56999 12.61 6.56999 12.26 6.56999L10.79 6.49999C9.67999 6.49999 8.78999 6.44999 7.71999 6.41999C6.64999 6.38999 5.62999 6.32999 4.57999 6.32999C2.38999 6.24999 0.409999 2.64999 0.0299988 0.419994H15.39C16.3747 0.322995 17.3686 0.445958 18.3 0.779995C19.2326 1.17479 20.0362 1.82251 20.62 2.64999C21.75 4.26999 21.54 5.92999 20.84 7.64999C20.41 8.71999 19.92 9.77999 19.39 10.81C18.7583 12.257 17.9196 13.6045 16.9 14.81C14.49 17.28 11.49 17.25 8.35 17.41C9.19 18.64 10.56 20 10.48 21.61C10.3282 22.8022 9.85842 23.9317 9.12 24.88L6.8 28.51L1.18999 37.33L0.0599976 39.13" fill="black"  id="dark"/>
<path opacity="0.54" d="M6.27 11.76C6.11765 12.7904 6.22031 13.8425 6.56899 14.824C6.91767 15.8056 7.50173 16.6866 8.27 17.39L11.1 17.22C11.1 17.22 15.35 17.07 17.32 14.22L14.65 12.4L12.01 10.59C11.6109 11.0536 11.0753 11.3791 10.48 11.52L6.27 11.76Z" fill="white" fill-opacity="0.4"  id="light"/>
<path opacity="0.62" d="M14.08 6.55C14.08 6.55 19.6 6.12 20.4 8.65C19.5513 10.5842 18.548 12.4469 17.4 14.22C16.07 13.4 12.09 10.59 12.09 10.59C12.9786 9.41011 13.7903 8.17422 14.52 6.89C14.59 6.72 14.28 6.54 14.08 6.55Z" fill="#888888"   id="mid"/>
</svg>
  )
}

export default BrandSymbol