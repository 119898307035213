import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

export default function Header() {
  const { open, close } = useWeb3Modal();
  const { isConnected } = useWeb3ModalAccount();
  const [isActive, setIsActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
    document.body.classList[isActive ? "remove" : "add"]("body-overflow");
  };
  const handlesubmitforConnect = async () => {
    await open();
  };
  // Sticky Menu Area
  // useEffect(() => {
  //   window.addEventListener("scroll", isSticky);
  //   return () => {
  //     window.removeEventListener("scroll", isSticky);
  //   };
  // });

  /* Method that will fix header after a specific scrollable */
  // const isSticky = (e) => {
  //   const header = document.querySelector(".header-main");
  //   const scrollTop = window.scrollY;
  //   scrollTop >= 100
  //     ? header.classList.add("is-sticky")
  //     : header.classList.remove("is-sticky");
  // };

  const handleClickHowToBuy = () => {
    handleClickMenu("howToBuy");
    document.getElementById("howToBuy").scrollIntoView({
      behavior: "smooth",
    });
  };
  const handleClickHero = () => {
    handleClickMenu("home");
    document.getElementById("main_wrapper").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleClickMenu = (menuTag) => {
    setActiveMenu(menuTag);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY >= 50);
    });
  }, []);

  return (
    <Index.Box
      className={`header-main ${scroll ? "is-sticky" : ""}`}
      component="header"
    >
      <Index.Box className="container">
        <Index.Box
          className={`header-row ${isActive ? "mobilemenu-active" : ""}`}
        >
          <Index.Box className="header-cust-col logo-wrape">
            <Index.Link className="header-logo-box full-logo" to="/">
              <PagesIndex.Svg.logo />
            </Index.Link>

            <Index.Link className="header-logo-box symbol-logo" to="/">
              <PagesIndex.Svg.logoSymbol />
            </Index.Link>

            <Index.Button className="mobile-menu-btn" onClick={handleClick}>
              <PagesIndex.Svg.BurgerMenu />
            </Index.Button>
          </Index.Box>
          <Index.Box className="header-cust-col navigation">
            <Index.Box className="header-nav-main">
              <Index.Box className="sidebar-brand-wrape">
                <Index.Link className="header-logo-box" to="/">
                  <PagesIndex.Svg.logo />
                </Index.Link>
                <Index.Button className="mobile-menu-btn" onClick={handleClick}>
                  <PagesIndex.Svg.Close />
                </Index.Button>
              </Index.Box>
              <Index.List className="header-nav-ul">
                <Index.ListItem className="header-nav-li">
                  <Index.Link
                    className={`header-nav-link ${
                      activeMenu == "home" ? "active" : ""
                    }`}
                    onClick={() => handleClickHero()}
                    to="/"
                  >
                    Home
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-nav-li">
                  <Index.Link
                    className={`header-nav-link ${
                      activeMenu == "howToBuy" ? "active" : ""
                    }`}
                    onClick={() => handleClickHowToBuy()}
                  >
                    How to Buy
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem
                  className={`header-nav-link ${
                    activeMenu == "whitepaper" ? "active" : ""
                  }`}
                >
                  <Index.Link
                    className="header-nav-link p-0"
                    onClick={() => handleClickMenu("whitepaper")}
                    to="https://plutopeio.notion.site/Whitepaper-50a6837d2ed440199331fb6f7936b639"
                    target="_blank"
                  >
                    WhitePaper
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem
                  className={`header-nav-link ${
                    activeMenu == "auditreport"
                      ? "active"
                      : "https://plutope.app/api/images/Plutope_Final_Report.pdf"
                  }`}
                >
                  <Index.Link
                    className="header-nav-link p-0"
                    onClick={() =>
                      window.open(
                        "https://plutope.app/api/images/Plutope_Final_Report.pdf",
                        "_blank"
                      )
                    }
                  >
                    Audit Report
                  </Index.Link>
                </Index.ListItem>

                {/* onClick={() => window.open(`/dashboard/transactions-list?state=${encodeURIComponent(JSON.stringify({ today }))}`, "_blank")} */}
                <Index.ListItem className="header-nav-li">
                  {isConnected ? (
                    <>
                      {/* <PagesIndex.Button primary>
                        BUY NOW
                      </PagesIndex.Button> */}
                      <Index.Box className="address-pad">
                        <w3m-button balance="hide" />
                      </Index.Box>
                    </>
                  ) : (
                    <PagesIndex.Button primary onClick={handlesubmitforConnect}>
                      Connect Now
                    </PagesIndex.Button>
                  )}
                  {/* <PagesIndex.Button primary children="fjaskdlf" />
                      <PagesIndex.Button primary >asdf</PagesIndex.Button> */}
                </Index.ListItem>

                {/* <Index.ListItem className='header-nav-li'>
                    <Index.Box className="wallet-address">
                      wallet address
                    </Index.Box>
                  </Index.ListItem> */}

                {/* <Index.ListItem className='header-nav-li'>
                    <PagesIndex.Button primary  onClick={handlesubmitforConnect} >
      
                    </PagesIndex.Button>
                    <w3m-button />
                    <PagesIndex.Button primary children="fjaskdlf" />
                    <PagesIndex.Button primary >asdf</PagesIndex.Button>
                  </Index.ListItem> */}
                {/* <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Staking</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Giveaways</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link'>Media</Index.Link>
                  </Index.ListItem> */}
              </Index.List>
            </Index.Box>
          </Index.Box>
          <Index.Box className="header-cust-col right-wrape">
            <Index.Box className="header-right-main social-media-wrape">
              <Index.Link to="https://t.me/plutopeofficial" className="icon fb">
                <PagesIndex.Svg.Telegram />
              </Index.Link>
              <Index.Link
                to="https://discord.com/invite/yXgfQ8Kpwn"
                className="icon twit"
              >
                <PagesIndex.Svg.Discord />
              </Index.Link>
              <Index.Link to="https://x.com/Plutopeio?t=3teWOyzUBrIlqfra6vkeFA&s=09" className="icon insta" target="_blank">
                <PagesIndex.Svg.Twit />
              </Index.Link>
            </Index.Box>
            {isConnected ? (
              <Index.Box className="header-account-wrape">
                <Index.Link to="/my-account" className="user-base">
                  <PagesIndex.Svg.UserAvatar />
                </Index.Link>
              </Index.Box>
            ) : (
              <></>
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
