import videoTwo from './images/video/videotwo.mp4';
import videoOne from './images/video/videoone.mp4';
import videoThree from './images/video/videothree.mp4';
import videoTwoGif from './images/video/videotwo.mp4';


const Video = {
    videoTwo,
    videoOne,
    videoThree,
    videoTwoGif,
}

export default Video;