import React from 'react'

function BurgureMenu() {
  return (
        <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg" id="burger-menu">
            <g clip-path="url(#clip0_37_2)">
                <path d="M35 0H0V5H35V0Z" fill="black" id="first-line"/>
                <path d="M26 14H0V19H26V14Z" fill="#007AFF" id="mid-line"/>
                <path d="M16.2 28H0V33H16.2V28Z" fill="black" id="last-line"/>
            </g>
            <defs>
                <clipPath id="clip0_37_2">
                    <rect width="35" height="33" fill="white"/>
                </clipPath>
            </defs>
        </svg>
  )
}

export default BurgureMenu