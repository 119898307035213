import React, { useState, useEffect, useRef } from "react";
import Index from "../../Index";
import PageIndex from "../../PagesIndex";
import FlipCountdown from "@rumess/react-flip-countdown";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import abi from "../../Connectivity/Abis/plutope.json";
import tokenAbi from "../../Connectivity/Abis/usdt.json";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Hero() {
  const contractAddress = "0x6fD1cf06c0667D7C71EE2Dc21e84E278737b2a2F";
  const divRef = useRef(null);
  const API_KEY = process.env.REACT_APP_API_KEY;
  const { open, close } = useWeb3Modal();
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    tokenSold: 0,
    totalTokenSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhase: "0",
    presaleIsActive: false,
  });
  const [selectedToken, setSelectedToken] = React.useState("USDT");
  const [getToken, setGetToken] = useState(0);
  const [tokenInBNB, setTokenInBNB] = React.useState("");
  const { walletProvider } = useWeb3ModalProvider();
  const [modalOpen, setModalOpen] = useState(false);
  const { isConnected, address } = useWeb3ModalAccount();

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDivClick = () => {
    document.body.classList.add("div-clicked");
  };

  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const getPhaseDetails = await contract.getPhasePricesAndTokens();

      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,
        tokenSold: getPhaseDetails[2].toString() / 10 ** 18,
        totalTokenSoldInPhases: getPhaseDetails[3].toString() / 10 ** 18,
        privateSalePhaseName: getPhaseDetails[4],
        activePhase: getPhaseDetails[5].toString(),
        presaleIsActive: getPhaseDetails[6],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleChangeInput = (e) => {
    const value = e.target.value;
    setGetToken(value);
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const weiValue = ethers.utils.parseEther(value.toString());
      contract
        .getBNBEquivalentUSDT(weiValue)
        .then((result) => {
          const getTokenPrice = parseInt(
            result / tokensData.currentPhasePrice / 10 ** 18
          ).toFixed();
          setTokenInBNB(getTokenPrice);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const buyTokens = async () => {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      "0xE2bE488d29f26f2e4b3827671266927dE42D41BC",
      tokenAbi,
      signer
    );
    const contract = new ethers.Contract(contractAddress, abi, signer);
    try {
      // setIsLoading(true);
      const weiValue = ethers.utils.parseEther(getToken.toString());
      if (selectedToken === "USDT") {
        const checkAllowence = await tokenContract.allowance(
          address,
          contractAddress
        );
        if (Number(checkAllowence.toString()) < Number(weiValue.toString())) {
          const approveToken = await tokenContract.approve(
            contractAddress,
            weiValue
          );
          await approveToken.wait();
        }
        const tx = await contract.buyPrivateSaleToken("0", weiValue);
        await tx.wait();
        // setIsLoading(false);
        toast.success("Transaction is successfully completed");
        allReadData();
      } else {
        // setIsLoading(true);
        const tx = await contract.buyPrivateSaleToken("1", weiValue, {
          value: weiValue,
        });
        await tx.wait();
        // setIsLoading(false);
        toast.success("Transaction is successfully completed");
        allReadData();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.error.data.message);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    allReadData();
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        document.body.classList.remove("div-clicked");
      }
    };
    // Attach event listener to the document for clicks outside the div
    document.addEventListener("click", handleClickOutside);
    // Clean up function
    return () => {
      // Remove event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  /* dd start */
  const [crypto, setCrypto] = React.useState("");

  const handleChange = (event) => {
    setCrypto(event.target.value);
  };
  /* dd end */

  /* countdown start */
  // const [countdownDate, setCountdownDate] = useState(new Date('12/25/2024').getTime());
  // const [state, setState] = useState({
  //   days: 0,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // });

  // useEffect(() => {
  //   setInterval(() => setNewTime(), 1000);
  // }, []);

  // const setNewTime = () => {
  //   if (countdownDate) {
  //     const currentTime = new Date().getTime();

  //     const distanceToDate = countdownDate - currentTime;

  //     let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
  //     let hours = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  //     );
  //     let minutes = Math.floor(
  //       (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
  //     );
  //     let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

  //     const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  //     days = `${days}`;
  //     if (numbersToAddZeroTo.includes(hours)) {
  //       hours = `0${hours}`;
  //     } else if (numbersToAddZeroTo.includes(minutes)) {
  //       minutes = `0${minutes}`;
  //     } else if (numbersToAddZeroTo.includes(seconds)) {
  //       seconds = `0${seconds}`;
  //     }

  //     setState({ days: days, hours: hours, minutes, seconds });
  //   }
  // };

  /* countdown end */

  // const [isShowFirstSection, setIsShowFirstSection] = React.useState(false);
  // const scrolled50Ref = React.useRef();
  // useEffect(() => {
  //   const scrollFun = () => {
  //     console.log("window.scrollY", window.scrollY);
  //     if (window.scrollY <= 80) {
  //       setIsShowFirstSection(false);
  //     } else {
  //       setIsShowFirstSection(true);
  //     }
  //   };

  //   window.addEventListener("scroll", scrollFun);

  //   return () => {
  //     window.removeEventListener("scroll", scrollFun);
  //   };
  // }, []);

  const [isShowFirstSection, setIsShowFirstSection] = useState(false);

  const handleScroll = () => {
    // Check both window.scrollY and window.innerWidth
    if (window.innerWidth < 2000 && window.scrollY <= 50) {
      setIsShowFirstSection(true);
    } else {
      setIsShowFirstSection(false);
    }
  };

  const handlesubmitforConnect = async () => {
    await open();
  };

  useEffect(() => {
    // Initial check
    handleScroll();

    // Add event listener for scroll and resize
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <>
      <Index.Box
        id="section-hero"
        className={
          isShowFirstSection
            ? "home-hero-main one"
            : "home-hero-main one animation-hide"
        }
      >
        <Index.Box className="container">
          <Index.Box className="hero-text-area">
            <Index.Typography component="h1" className="text">
              BUY PLT with BNB/USDT
              {/* <Index.Box component="span" className="sub"> dolor</Index.Box> */}
            </Index.Typography>
            <Index.Typography component="p">
              PLT is the native-token of PlutoPe and is a BEP-20 token. During
              the pre-sale, you can buy PLT with BNB or USDT on Binance Smart
              Chain.
            </Index.Typography>
            <Index.Box className="btn-wrape">
              <PageIndex.Button
                primary
                onClick={() =>
                  window.open(
                    " https://plutope.app/api/images/PLT_Token_Whitepaper_V1.4_Updated.pdf",
                    "_blank"
                  )
                }
              >
                Litepaper
              </PageIndex.Button>
              {/* <PageIndex.Button secondary>Watch Video</PageIndex.Button> */}
            </Index.Box>
          </Index.Box>
          <ToastContainer />
          <Index.Box className="presale-wrape">
            <Index.Typography component="h4">
              {tokensData.privateSalePhaseName}
              <Index.Box className="label-wrape">
                <PageIndex.OpenLabel />
              </Index.Box>
            </Index.Typography>

            <Index.Box className="transfer-wrape">
              <Index.Box className="text-wrape">
                <Index.Typography component="h6">
                  <Index.Box className="icon">
                    <PageIndex.Svg.BNB />
                  </Index.Box>{" "}
                  Buy with BSC
                </Index.Typography>

                <Index.Typography component="h6">
                  {/* 1 PLT = $0.033 */}1 PLT = ${tokensData.currentPhasePrice}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="gray-pad">
                <Index.Typography component="p">You Pay</Index.Typography>
                <Index.Box className="inner-pad">
                  <Index.Box className="input-wrape">
                    <Index.Box
                      className="input-pad"
                      ref={divRef}
                      onClick={handleDivClick}
                    >
                      <Index.TextField
                        placeholder="Enter amount"
                        type="number"
                        onChange={handleChangeInput}
                      ></Index.TextField>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-wrape">
                    <Index.Box className="input-pad">
                      <Index.FormControl fullWidth>
                        <Index.Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedToken}
                          onChange={(e) => setSelectedToken(e.target.value)}
                          label="crypto"
                        >
                          <Index.MenuItem value={"USDT"}>
                            <Index.Box className="dd-icon">
                              <PageIndex.Svg.TUSDT />
                            </Index.Box>
                            USDT
                          </Index.MenuItem>
                          <Index.MenuItem value={"BNB"}>
                            <Index.Box className="dd-icon">
                              <PageIndex.Svg.BNB />
                            </Index.Box>
                            BNB
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Typography component="p" className="base-price">
                  {tokensData.privateSalePhaseName} Price{" "}
                  <Index.Typography component="b">
                    {tokensData.currentPhasePrice}
                  </Index.Typography>
                </Index.Typography>
              </Index.Box>

              <Index.Box className="gray-pad">
                <Index.Typography component="p">You Receive</Index.Typography>
                <Index.Typography component="h1">
                  <Index.Box className="p-currency">
                    <PageIndex.Svg.logoSymbol />
                  </Index.Box>
                  {selectedToken === "USDT"
                    ? getToken === 0
                      ? 0
                      : parseInt(
                          getToken / tokensData.currentPhasePrice
                        ).toFixed()
                    : getToken === 0
                    ? 0
                    : tokenInBNB}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="progress-wrape">
                <Index.Typography component="p">
                  Until next price{" "}
                  <Index.Typography component="b">
                    ${tokensData.nextPhasePrice}
                  </Index.Typography>
                </Index.Typography>
                <Index.Box className="progress-pad">
                  <Index.LinearProgress variant="determinate" value={50} />
                </Index.Box>
                <Index.Typography component="p">
                  <span>{tokensData.tokenSold.toFixed()} PLT</span> /
                  <span>{tokensData.totalTokenSoldInPhases} PLT</span>
                </Index.Typography>
              </Index.Box>

              <Index.Box className="footer-action">
                {isConnected ? (
                  <Index.Box className="Btn-main-box">
                    <Index.Button
                      onClick={buyTokens}
                      className="submit-btn wallet-connetct"
                    >
                      <Index.Box component="span" className="icon">
                        <PageIndex.Svg.ConnectWallet />
                      </Index.Box>{" "}
                      BUY NOW
                    </Index.Button>
                    <Index.Link
                      to={`https://buy.onramper.com/?apiKey=pk_prod_01HETEQF46GSK6BS5JWKDF31BT&mode=buy&wallets=ava_ethereum:${address}`}
                      target="_blank"
                    >
                      <Index.Button className="submit-btn wallet-connetct">
                        <Index.Box component="span" className="icon">
                          <PageIndex.Svg.ConnectWallet />
                        </Index.Box>{" "}
                        TOP UP
                      </Index.Button>
                    </Index.Link>
                  </Index.Box>
                ) : (
                  <Index.Button
                    className="submit-btn wallet-connetct"
                    onClick={handlesubmitforConnect}
                  >
                    <Index.Box component="span" className="icon">
                      <PageIndex.Svg.ConnectWallet />
                    </Index.Box>{" "}
                    Connect Wallet
                  </Index.Button>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <div className="countdown-wrapper">
            <Index.Typography component="h5" className="box-head-text">
              ENDING SOON!
            </Index.Typography>
            <Index.Typography component="p">
              Grab your hands on PLT now, the clock is ticking…
            </Index.Typography>
            {/* <Index.Box className="countdown-pad">
                <Index.Box className='time-section'>
                  <Index.Box className='time'>{state.days || '0'}</Index.Box>
                  <Index.Box component="small" className="time-text">Days</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='dots'>:</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='time'>{state.hours || '00'}</Index.Box>
                  <Index.Box component="small" className="time-text">Hours</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='dots'>:</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='time'>{state.minutes || '00'}</Index.Box>
                  <Index.Box component="small" className="time-text">Minutes</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='dots'>:</Index.Box>
                </Index.Box>
                <Index.Box className='time-section'>
                  <Index.Box className='time'>{state.seconds || '00'}</Index.Box>
                  <Index.Box component="small" className="time-text">Seconds</Index.Box>
                </Index.Box>
              </Index.Box> */}

            <Index.Box className="countdown-pad">
              <FlipCountdown
                hideYear
                hideMonth
                endAt={"2024-12-12 01:26:58"} // Date/Time
                titlePosition="bottom"
                theme="dark"
              />
            </Index.Box>
          </div>
        </Index.Box>

        <Index.Box className="glass-bg">
          <img src={PageIndex.Png.glass} alt="" />
        </Index.Box>
      </Index.Box>

      <Index.Modal open={modalOpen} onClose={handleModalClose}>
        <Index.Box className="top-up-modal-wrap">
          <Index.Box className="iframe-wrap">
            <iframe
              src={`https://buy.onramper.com/?apiKey=pk_prod_01HETEQF46GSK6BS5JWKDF31BT&mode=buy&wallets=ava_ethereum:${address}`}
              className="main-iframe"
            ></iframe>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
