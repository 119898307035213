import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from 'history'

//#region user
import { useSelector } from "react-redux";
import Layout from "../container/user/pages/layout/Layout";
import Home from "../container/user/pages/home/Home";
import HomeTwo from "../container/user/pages/home/HomeTwo";
import HomeTextOne from "../container/user/pages/home/HomeTextOne";
import HomeTextTwo from "../container/user/pages/home/HomeTextTwo";
import HomeBgGlass from "../container/user/pages/home/HomeBgGlass";
import HomeParallax from "../container/user/pages/home/HomeParallax";
import HomeSemiParallax from "../container/user/pages/home/HomeSemiParallax";
import HomeParallaxOne from "../container/user/pages/home/HomePrallaxOne";
import HomeParallaxTwo from "../container/user/pages/home/HomeParallaxTwo";

import MyAcount from "../container/user/pages/myaccount/MyAcount";
import NewHome from "../container/user/pages/dashboard/NewHome";
import HowToBuy from "../component/common/howtobuy/HowToBuy";
//#endregion



const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/" element={<Layout />}>
        <Route path="/" element={<NewHome/>} />
        <Route path="/:referraladdress" element={<NewHome/>} />
        <Route path="/my-account" element={<MyAcount/>} />
        <Route path="/howToBuy" element={<HowToBuy/>} />
          {/* <Route path="/" element={<HomeParallaxOne/>} />
          <Route path="/home-option" element={<HomeTwo/>} />
          <Route path="/home-text-1" element={<HomeTextOne/>} />
          <Route path="/home-text-2" element={<HomeTextTwo/>} />
          <Route path="/home-glass-bg" element={<HomeBgGlass/>} />
          <Route path="/home-parallax" element={<HomeParallax/>} />
          <Route path="/home-semi-parallax" element={<HomeSemiParallax/>} />
          <Route path="/home-parallax-two" element={<HomeParallaxTwo/>} />
          
          <Route path="/my-account" element={<MyAcount/>} /> */} 
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
