import React, { useState } from 'react'
import Chart from "react-apexcharts";

export default function DonuteChart(props) {
    const [state, setState] = useState ({
          
      series: [44, 55, 41, 17, 15],
      options: {
        chart: {
          width: 380,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function(val, opts) {
            // return val + " - " + opts.w.globals.series[opts.seriesIndex]
            return opts.w.globals.series[opts.seriesIndex]
          }
        },
        title: {
          text: ''
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },     
      
      })
  return (
    <Chart
        options={state.options}
        series={state.series}
        type="donut"
    />
  )
}