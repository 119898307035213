import React from 'react'

function AppStore() {
  return (    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_236_874)">
            <path d="M16.6186 0H16.7893C16.9263 1.69253 16.2803 2.95719 15.4952 3.87301C14.7248 4.78251 13.6699 5.6646 11.9636 5.53076C11.8498 3.86247 12.4969 2.69161 13.281 1.77789C14.0082 0.92636 15.3413 0.168621 16.6186 0ZM21.7837 17.6167V17.6641C21.3042 19.1163 20.6202 20.361 19.7855 21.516C19.0236 22.5646 18.0898 23.9758 16.4226 23.9758C14.9819 23.9758 14.025 23.0494 12.5485 23.0241C10.9867 22.9988 10.1278 23.7987 8.69976 24H8.21287C7.16426 23.8482 6.31799 23.0178 5.70147 22.2695C3.88353 20.0585 2.47871 17.2025 2.21735 13.5476V12.4737C2.328 9.85799 3.59898 7.73126 5.28835 6.70057C6.17993 6.15255 7.40559 5.68568 8.77037 5.89435C9.35527 5.98498 9.95282 6.18522 10.4766 6.38335C10.973 6.5741 11.5937 6.9124 12.1818 6.89448C12.5801 6.88289 12.9764 6.67527 13.3779 6.52878C14.5541 6.10407 15.707 5.61718 17.2267 5.84587C19.0531 6.12199 20.3493 6.93347 21.1503 8.18548C19.6053 9.16875 18.3839 10.6505 18.5925 13.1809C18.778 15.4794 20.1143 16.8241 21.7837 17.6167Z" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_236_874">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  )
}

export default AppStore