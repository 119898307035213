import React, { useRef, useEffect } from "react";
import ScrollHead from "./scrollhead/ScrollHead";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

export default function HowToBuy() {
  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();

  const navHeader = [
    {
      headerTitle: "PLT",
      headerTitleDetail: "Presale Summary",
      headerRef: section1Ref,
      headerID: "presaleSummary",
    },
    {
      headerTitle: "Guide",
      headerTitleDetail: "Step-by-step",
      headerRef: section2Ref,
      headerID: "stepByStep",
    },
    {
      headerTitle: "Guide",
      headerTitleDetail: "Step-by-step",
      headerRef: section2Ref,
      headerID: "stepByStep2",
    },
  ];

  return (
    <Index.Box className="how-to-buy-wrape">
      {/* <Index.Box className="d-flex-buy"> */}
      <Index.Box className="left-section">
        <Index.Box className="head-section">
          <Index.Box className="title-wrape">
            <Index.Typography component="p" className="head-label">
              How To Buy
            </Index.Typography>
            <Index.Box className="head-line-flex">
              <Index.Typography component="h4">
                Buy PLT <Index.Box component="span">PlutoPe Token</Index.Box>
              </Index.Typography>
            </Index.Box>
            <Index.Typography component="p">
              You can buy PLT with BNB or USDT on Binance Smart Chain.
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="right-section">
        <Index.Box className="inner-section">
          <Index.Box className="section" ref={section1Ref} id="presaleSummary">
            <Index.Typography component="h3" className="sticky-header">
              PLT presale Summary
            </Index.Typography>
            <Index.Typography component="ul">
              <Index.Typography component="li">
                <b>Token Name:</b>
                PLT (PlutoPe Token)
              </Index.Typography>
              <Index.Typography component="li">
                <b>Purchase Method:</b> Users can buy PLT tokens using USDT
                (Tether) or BNB on the Binance Smart Chain (BSC). PlutoPe's
                token PLT is a BEP-20 token.
              </Index.Typography>
              <Index.Typography component="li">
                <b>Lock-up Period:</b> The purchased PLT tokens will be locked
                for a total duration of 2 years and 3 months, The first unlock
                will be 3 months after the pre-seed ends, and then there will be
                monthly unlocks for the next 24 months.
              </Index.Typography>
              <Index.Typography component="li">
                <b>Partial Withdrawal:</b> After the first 3 months of the
                lock-up period, users can withdraw 4.166% of the tokens they
                initially bought. This implies that only 4.166% of the purchased
                tokens will be available for withdrawal every month.
              </Index.Typography>
            </Index.Typography>
          </Index.Box>
          <Index.Box className="section" ref={section2Ref} id="stepByStep">
            <Index.Typography component="h3" className="sticky-header">
              How to buy?
            </Index.Typography>
            <Index.Typography component="ul">
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                Go to the Presale website and then Tap on the Connect Wallet
                button and choose your preferred wallet.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                Choose your preferred buying method BNB or USDT and Enter the
                quantity of PLT tokens that your want to buy and Tap on BUY.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                It will prompt you to set a threshold for your dApp interaction.
                This acts as an extra shield to protect your assets from
                unexpected attacks.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                Confirm the transaction on your wallet. and Again, tap on the
                Buy button. Sign the final transaction on your wallet.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                After your purchase is successful, you can view your position on
                your dashboard.
              </Index.Typography>
            </Index.Typography>
          </Index.Box>
          <Index.Box className="section" ref={section3Ref} id="stepByStep2">
            <Index.Typography component="h3" className="sticky-header">
              How to withdraw your PLT tokens?
            </Index.Typography>
            <Index.Typography component="p">
              You can prematurely withdraw up to 4.166% of your PLT tokens after
              3 months and the same percentage that is 4.166% will be unlocked
              every consecutive month for the next 24 months. Here’s how -
            </Index.Typography>
            <br />
            <Index.Typography component="ul">
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>
                Go to the Presale website here.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>{" "}
                Tap on the Connect Wallet button and choose your preferred
                wallet. Make sure you are choosing the same account with which
                you purchased PLT tokens.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>{" "}
                Go to the Dashboard section
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>{" "}
                Tap on withdraw and enter the amount of tokens that you want to
                withdraw.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>{" "}
                Hit continue. It will open your wallet app. Confirm the
                transaction.
              </Index.Typography>
              <Index.Typography component="li">
                <Index.Box component="span" className="icon">
                  <PagesIndex.Svg.GreenBulletIcon />
                </Index.Box>{" "}
                Viola, you have successfully withdrawn your PLT tokens in your
                wallet.
              </Index.Typography>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </Index.Box> */}
    </Index.Box>
  );
}
