import React from 'react';
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import { useMemo } from "react";
import { FlashOnRounded } from '@mui/icons-material';

export default function PieChart() {
    const chartOptions = useMemo(
        () => ({
            tooltip: {
                trigger: "item",
                formatter: function(params) {
                    return `${params.marker} ${params.name}: ${params.value} (${params.percent}%) <span style="width: 100%; display: block;">${params.data.amount}</span> `;
                },
                // Additional tooltip configuration
                confine: true
            },
            legend: {
              orient: "horizontal",
              left: "left",
              type: "scroll",
              bottom: -5,
            },
            series: [
                {
                    name: "Stack Wise",
                    type: "pie",
                    radius: "75%",
                    label: {
                        textShadowColor: 'transparent',
                        borderWidth: 0,
                    },
                    data: [
                        { value: 18, name: "Partnerships/Exchanges", amount: "40,500,000", },
                        { value: 18.2, name: "Treasury", amount: "40,875,000", },
                        { value: 2.5, name: "Advisors", amount: "5,625,000", },
                        { value: 20.0, name: "Ecosystem", amount: "45,000,000", },
                        { value: 12.0, name: "Founder/Team", amount: "27,000,000", },
                        { value: 5.00, name: "Public sale", amount: "11,250,000", },
                        { value: 6.0, name: "Strategic", amount: "13,500,000", },
                        { value: 13.3, name: "Seed", amount: "30,000,000", },
                        { value: 5.0, name: "Pre Seed", amount: "11,25,000", },
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: "rgba(0, 0, 0, 0.5)"
                        }
                    }
                }
            ], 
            
            media: [
                {
                    query: {
                        minWidth: 700,
                    },
                    option: {
                        legend: {
                            orient: "vertical",
                            left: "right",
                        },
                        series: {
                            radius: "85%",
                        },                        
                    }
                },
                {
                    query: {
                        minWidth: 1200,
                    },
                    option: {                        
                        height: '480px',
                        textStyle: {
                            fontSize: '15',
                        },
                        legend: {
                            orient: "vertical",
                            left: "right",
                            top: "center",
                        }
                    }
                },
                {
                    query: {
                        maxWidth: 767 // Adjust this value according to your mobile breakpoint
                    },
                    option: {
                        series: [{
                            label: {
                                show: false // Hide labels on mobile devices
                            }
                        }]
                    }
                }
            ],       
        }),
        []
    );

    return (
        <div className="App" style={{ margin: "20px" }}>
            <EChartsReact option={chartOptions} opts={{renderer: 'svg'}} />
        </div>
    );
}
