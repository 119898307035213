import React, { useState } from 'react';
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';

const ReadMoreReadLessBox = ({ items, bulletIcon }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Function to toggle between expanded and collapsed states
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    // Determine which items to display based on the state
    const displayedItems = isExpanded ? items.slice(-3) : items.slice(0, 3);

    return (
        <Index.Box>
            {
                displayedItems.map((item, index) => (
                    <Index.Box className="roadmap-bullet">
                        <Index.Box className="bullet-icon">
                            {bulletIcon}
                        </Index.Box>
                        <Index.Box className="bullet-text">
                            {item}
                        </Index.Box>
                    </Index.Box>
                ))
            }
            <Index.Typography className='read-more-text' onClick={toggleExpansion}>
                {isExpanded ? 'Read Less' : 'Read More'}
            </Index.Typography>

        </Index.Box>


    );
};

export default ReadMoreReadLessBox;
