import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PageIndex from "../../PagesIndex";
import abi from "../../Connectivity/Abis/plutope.json";
import { ethers } from "ethers";
import Paper from "@mui/material/Paper";

export default function Table() {
  const [tokonomicsData, setTokonomicsData] = useState([]);
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    totalTokensSold: 0,
    totalTokensSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhaseIndex: "0",
    isPrivateSaleActive: false,
  });

  const contractAddress = "0x6fD1cf06c0667D7C71EE2Dc21e84E278737b2a2F";
  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(contractAddress, abi, provider);
    try {
      const getPhaseDetails = await contract.getPhasePricesAndTokens();

      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,
        totalTokensSold: getPhaseDetails[2].toString() / 10 ** 18,
        totalTokensSoldInPhases: getPhaseDetails[3].toString() / 10 ** 18,
        privateSalePhaseName: getPhaseDetails[4],
        activePhaseIndex: getPhaseDetails[5].toString(),
        isPrivateSaleActive: getPhaseDetails[6],
      });
      const getTokonomicsDetails = await contract.getTokonomicsData();
      const price = await contract.getPhasePrice();
      const optimizedData = getTokonomicsDetails
        .slice(0, 3)
        .map((element, index) => {
          const totalRaise =
            (element[2].toString() / 10 ** 18) *
            (price[index]?.toNumber() || 0);
          return {
            OfferingType: element[0],
            Quantity: element[2].toString() / 10 ** 18,
            Price: (price[index]?.toNumber() || 0) / 10000,
            Raise: totalRaise / 10000,
          };
        });
      setTokonomicsData(optimizedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    allReadData();
  }, []);
  /* table end */
  return (
    <>
      <Index.Box className="table-wrape py-50">
        <Index.Box className="table-scroll">
          <Index.TableContainer component={Paper}>
            <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell> </Index.TableCell>
                  <Index.TableCell>Type</Index.TableCell>
                  <Index.TableCell align="right">Quantity</Index.TableCell>
                  <Index.TableCell align="right">Price</Index.TableCell>
                  <Index.TableCell align="right">Raise</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {tokonomicsData.map((row) => (
                  <Index.TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <Index.TableCell component="td">
                      <Index.Box className="table-icon">
                        <PageIndex.Svg.Unlock />
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell component="td" data-label="Type">
                      {row.OfferingType}
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      align="right"
                      data-label="Quantity"
                    >
                      {Number(row.Quantity).toFixed(2)}
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      align="right"
                      data-label="Price"
                    >
                      ${row.Price}
                    </Index.TableCell>
                    <Index.TableCell
                      component="td"
                      align="right"
                      data-label="Raise"
                    >
                      ${Number(row.Raise).toFixed(2)}
                    </Index.TableCell>
                  </Index.TableRow>
                ))}
                {/* {tokonomicsData.map((data, index) => (
                        <Index.Box className="offering-tr gradient-box">
                          <Index.Box className="offering-tr-main space-between gradient-inner-box">
                            {index <= tokensData.activePhaseIndex &&
                            tokensData.isPrivateSaleActive === true ? (
                              <Index.Box className="lock-main gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <img
                                    src={Index.Svg.unlock}
                                    alt="lock"
                                    className="lock-ic"
                                    style={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.isPrivateSaleActive === true
                                          ? "lightgreen !important"
                                          : "white",
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            ) : 2 === parseInt(tokensData.activePhaseIndex) &&
                            tokensData.isPrivateSaleActive === false ? (
                              <Index.Box className="th-blank">
                                <Index.Box className="lock-main gradient-box">
                                  <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                    <img
                                      src={Index.Svg.unlock}
                                      alt="lock"
                                      className="lock-ic"
                                      style={{
                                        color:
                                          data.OfferingType ===
                                            tokensData.privateSalePhaseName &&
                                          tokensData.isPrivateSaleActive === true
                                            ? "lightgreen !important"
                                            : "white",
                                      }}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            ): (
                              <Index.Box className="lock-main gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <img
                                    src={Index.Svg.padlock}
                                    alt="lock"
                                    className="lock-ic"
                                    style={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.isPrivateSaleActive === true
                                          ? "lightgreen !important"
                                          : "white",
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>
                            )}
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text offering-pre-seed"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.isPrivateSaleActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              {data.OfferingType}
                            </Index.Typography>
                            <Index.Box className="offering-list d-flex">
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.isPrivateSaleActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    {Number(data.Quantity).toFixed(2)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.isPrivateSaleActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    ${data.Price}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="offering-td-box quantity-box gradient-box">
                                <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="offering-th-text"
                                    sx={{
                                      color:
                                        data.OfferingType ===
                                          tokensData.privateSalePhaseName &&
                                        tokensData.isPrivateSaleActive === true
                                          ? "#18BF2E !important "
                                          : "white",
                                    }}
                                  >
                                    ${Number(data.Raise).toFixed(2)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ))} */}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
    </>
  );
}
