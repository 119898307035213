import BNB from "./images/svg/Bnb";
import TUSDT from "./images/svg/Tusdt";
import logo from "./images/svg/Brand";
import logoSymbol from "./images/svg/BrandSymbol";
import logoSymbolBlack from "./images/svg/BrandSymbolBlack";
import logoWhite from "./images/svg/brand-white.svg";
import ConnectWallet from "./images/svg/ConnectWallet";
import Unlock from "./images/svg/Unlock";
import Fb from "./images/svg/Fb";
import Insta from "./images/svg/Insta";
import Twit from "./images/svg/Twit";
import BurgerMenu from "./images/svg/BurgureMenu";
import Close from "./images/svg/Close";
import BlackBulletIcon from "./images/svg/BlackBulletIcon";
import GreenBulletIcon from "./images/svg/GreenBulletIcon";
import BlueBulletIcon from "./images/svg/BlueBulletIcon";
import ModalCloseIcon from "./images/svg/ModalCloseIcon";
import PrevArrow from "./images/svg/PrevArrow";
import NextArrow from "./images/svg/NextArrow";
import AppStore from "./images/svg/AppStore";
import GooglePlayStore from "./images/svg/GooglePlayStore";
import UserAvatar from "./images/svg/UserAvatar";
import ViewIcon from "./images/svg/ViewIcon";
import Telegram from "./images/svg/Telegram";
import Discord from "./images/svg/Discord";
import closeIcon from "./images/svg/close.svg";

const Svg = {
  logo,
  logoSymbol,
  logoSymbolBlack,
  BNB,
  TUSDT,
  ConnectWallet,
  Unlock,
  Fb,
  Insta,
  Twit,
  BurgerMenu,
  Close,
  BlackBulletIcon,
  GreenBulletIcon,
  BlueBulletIcon,
  ModalCloseIcon,
  PrevArrow,
  NextArrow,
  logoWhite,
  AppStore,
  GooglePlayStore,
  UserAvatar,
  ViewIcon,
  Telegram,
  Discord,
  closeIcon,
};

export default Svg;
