import React from 'react'

function Fb() {
  return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1855 24V13.2H17.4644L18 8.4H14.1855V6.06211C14.1855 4.82611 14.2171 3.6 15.9443 3.6H17.6936V0.168164C17.6936 0.116564 16.191 0 14.6708 0C11.496 0 9.50808 1.98863 9.50808 5.64023V8.4H6V13.2H9.50808V24H14.1855Z" fill="black"/>
</svg>
  )
}

export default Fb