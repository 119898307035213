import React from 'react'

export default function Telegram() {
    return (
        <>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 511.999 511.999"  >
<g>
	<g>
		<path d="M501.974,41.949c-8.237-7.547-19.944-10.062-30.555-6.565L21.231,183.785c-12.269,4.044-20.589,14.996-21.196,27.899
			c-0.606,12.904,6.649,24.589,18.483,29.768l90.405,39.554l266.17-147.723c6.501-3.608,14.66-1.922,19.2,3.961
			c4.541,5.885,4.1,14.204-1.037,19.576L227.319,330.325L193.287,453.28l81.681-61.162l96.589,79.039
			c5.626,4.606,12.502,7.008,19.513,7.008c3.338-0.001,6.708-0.545,9.986-1.657c10.166-3.447,17.702-11.744,20.159-22.193
			l89.965-382.503C513.737,60.939,510.211,49.496,501.974,41.949z"/>
	</g>
</g>
<g>
	<g>
		<path d="M132.476,302.325l31.261,144.992l35.612-128.661c0.662-2.394,1.907-4.587,3.623-6.381l100.029-104.59L132.476,302.325z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

        </>
    )
}
