
import Svg from '../assets/Svg';
import Png from "../assets/Png";
import Jpg from "../assets/jpg";
import Video from "../assets/Video";


import Header from "../component/user/defaulLayout/Header";
import Footer from "../component/user/defaulLayout/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';

import OpenLabel from "../component/common/label/OpenLabel";
import CloseLabel from "../component/common/label/CloseLabel";

import Button from "../component/common/button/Button";
import DonuteChart from '../component/common/charts/DonuteChart';
import PieChart from '../component/common/charts/PieChart';

import RoadMap from '../component/common/roadMap/RoadMap';
import SignUpModal from '../component/common/modal/SignUpModal';
import Faq from '../component/common/faq/Faq';
import HowToBuy from '../component/common/howtobuy/HowToBuy';
import Hero from '../component/common/hero/Hero';
import NewHero from '../component/common/hero/NewHero';
import HeroTwo from '../component/common/hero/HeroTwo';
import Table from '../component/common/table/Table';
import NewChart from '../component/common/charts/NewChart';

export default {
      Svg,
      Png,
      Jpg,
      Header,
      Footer,
      Swiper,
      SwiperSlide,
      Navigation,
      Pagination,
      OpenLabel,
      CloseLabel,
      Button,
      DonuteChart,
      PieChart,
      RoadMap,
      SignUpModal,
      Faq,
      HowToBuy,
      Hero,
      Table,
      HeroTwo,
      Video,
      NewHero,
      NewChart,
};