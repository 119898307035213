import * as React from 'react';
import Index from '../../Index';
import PagesIndex from '../../PagesIndex';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SignUpModal() {

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Index.Button onClick={handleOpen}>Open modal</Index.Button> */}
      {/* keepMounted */}
      <Index.Modal
        open={open}
        onClose={handleClose}
        className='modal-wrape'
      >
        <Index.Box sx={style} className="modal-pad">
          <Index.Link onClick={handleClose} className='modal-close'>
            <PagesIndex.Svg.ModalCloseIcon />
          </Index.Link>
          <Index.Typography component="h2">
            Gotta go ask momma before <Index.Box component="span" className='frame'> you enlist?</Index.Box>
          </Index.Typography>
          <Index.Typography component="p">
            Signup for exclusive updates whilst you await, recruit!
          </Index.Typography>
          
          <Index.Box className="form-wrape">
            <Index.Box className="input-wrape">
              <Index.Box className="input-pad">
                <Index.TextField placeholder="Enter your email" type="text"></Index.TextField>
              </Index.Box>
            </Index.Box>

            <PagesIndex.Button primary>
                Sign Up
            </PagesIndex.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  )
}
