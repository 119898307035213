import React, { useState, useEffect } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";

function NewHeader() {
  const handleClick = () => {
    document.getElementById("HowToBuy").scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <>
      <Index.Box className="header-main-wrapper">
        <Index.Box className="container">
          <Index.Box className="header-main">
            <Index.Box className="header-logo-sec">
              <Index.Link className="header-logo" to="/">
                <PagesIndex.Svg.logo />
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-menu-sec">
              <Index.List className="header-nav-ul">
                <Index.ListItem className="header-nav-li">
                  <Index.Link className="header-nav-link active">
                    Home
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-nav-li">
                  <Index.Link className="header-nav-link" onClick={handleClick}>
                    How to Buy
                  </Index.Link>
                </Index.ListItem>
                <Index.ListItem className="header-nav-li">
                  <Index.Link
                    className="header-nav-link"
                    to="https://plutope.app/api/images/PLT_Token_Whitepaper_V1.4_Updated.pdf"
                    target="_blank"
                  >
                    WhitePaper
                  </Index.Link>
                </Index.ListItem>
                <PagesIndex.Button primary>Connect Now</PagesIndex.Button>
              </Index.List>
            </Index.Box>
            <Index.Box className="header-social-link-sec"></Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NewHeader;
