import parallaxOne from './images/jpg/parallaxOne.jpg';
import parallaxTwo from './images/jpg/parallaxTwo.jpg';
import parallaxThree from './images/jpg/parallaxThree.jpg';
import parallaxFour from './images/jpg/parallaxFour.jpg';
import parallaxFive from './images/jpg/parallaxFive.jpg';


const Jpg = {
    parallaxOne,
    parallaxTwo,
    parallaxThree,
    parallaxFour,
    parallaxFive,
}

export default Jpg;