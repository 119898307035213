import React from 'react'
import PagesIndex from '../../../PagesIndex'
import PageIndex from "../../../PagesIndex";
import Index from '../../../../component/Index';
import { UseEffectScroll } from 'react-use-smooth-scroll'


function NewHome() {
    return (
        <>
            <UseEffectScroll >
                <Index.Box className="" id="main_wrapper">
                    <PagesIndex.NewHero />
                    <Index.Box className="new-table-sec sec-padding">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg py-80 cus-height">
                                <Index.Box className="table-main-wrapper">
                                    <PageIndex.Table />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="new-chart-sec sec-padding">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg py-80 chart-bg-gif">
                                <Index.Box className="table-main-wrapper">
                                    <PageIndex.NewChart />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="new-roadmap-sec sec-padding cus-sticky">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg py-80 cus-height bg-white">
                                <Index.Box className="table-main-wrapper">
                                    <PageIndex.RoadMap />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="new-howToBuy-sec cus-sticky" id="howToBuy">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg py-80 bg-white">
                                <PageIndex.HowToBuy />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="new-faq-sec cus-sticky">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg py-80 cus-height bg-white">
                                <PageIndex.Faq />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="new-footer-sec cus-sticky">
                        <Index.Box className="container">
                            <Index.Box className="box-frame glass-bg cus-height bg-white">
                                <PageIndex.Footer />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="full-bg-glass">
                    <img src={PageIndex.Png.glass}/>
                </Index.Box> */}
                </Index.Box>
            </UseEffectScroll>
        </>
    )
}

export default NewHome
