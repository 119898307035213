import React from 'react'

export default function PrevArrow() {
  return (
    <>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1539 17.6534L11.2077 16.5688L7.38845 12.7495H19.5V11.2496H7.38845L11.2077 7.4303L10.1539 6.3457L4.50005 11.9995L10.1539 17.6534Z" fill="black"></path>
        </svg>
    </>
  )
}
